import { DmbDateString } from '@dmb/core';
import * as t from 'io-ts';

export const NewsC = t.type({
  id: t.number,
  title: t.string,
  teaser: t.string,
  content: t.string,
  publishDate: DmbDateString,
  requiresConfirmation: t.boolean,
});
export type News = t.TypeOf<typeof NewsC>;

import { Dialog } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';
import { toggleBackgroundBlur } from '../../helper/modal.helper';

type DmbModalConfig<C> = {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  disableClose?: boolean;
  data?: C;
  maxHeight?: number;
  maxWidth?: number;
};

const DEFAULT_CONFIG: DmbModalConfig<null> = {
  hasBackdrop: true,
  backdropClass: 'modal-backdrop',
  panelClass: 'modal-panel',
  disableClose: true,
};

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private dialog: Dialog) {}

  openDialog<C>(component: ComponentType<C> | TemplateRef<C>, param?: unknown, data?: DmbModalConfig<C>) {
    const config = Object.assign({}, DEFAULT_CONFIG, data, { data: param });
    toggleBackgroundBlur(true);
    return this.dialog.open(component, config);
  }

  hideBlur() {
    toggleBackgroundBlur(false);
  }

  isOpen() {
    return !!this.dialog;
  }
}

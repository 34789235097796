import { flattenJson } from './flatten-json';
import { MutateOperation, MutationCommand } from './model';

export const objectToMutationCommand = (
  commandKey: string,
  path: string,
  object: Record<symbol | string | number, unknown>,
): MutationCommand =>
  new MutationCommand(
    commandKey,
    path,
    Object.entries(flattenJson(object)).map(([k, v]) => new MutateOperation(k, 'replace', v)),
  );

import { DmbDateString } from '@dmb/core';
import * as t from 'io-ts';

export const PlaylistEntryC = t.type({
  playlistName: t.string,
  link: t.string,
  trackId: t.number,
  trackTitle: t.string,
  total: t.number,
  storeId: t.number,
  addedOn: t.union([t.null, DmbDateString]),
  removedOn: t.union([t.null, DmbDateString]),
  prevPosition: t.union([t.null, t.number]),
  currentPosition: t.union([t.null, t.number]),
});

export type PlaylistEntry = t.TypeOf<typeof PlaylistEntryC>;

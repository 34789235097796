import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';
import { ChartEntryC } from './chart-entry';
import { getDashboardWidgetArrayType, getDashboardWidgetType } from './dashboard-widget';
import { DashboardWidgetName, DashboardWidgetNameC } from './dashboard-widget-name';
import {
  emptyTrendReportsDownloads,
  emptyTrendReportsStreams,
  StreamsC,
  TrendReportsDownloadsC,
} from './highcharts-config';
import { InStoreC } from './in-store';
import { NewsC } from './news';
import { PlaylistEntryC } from './playlist-entry';
import { ProductImportC } from './product-import';
import { ProductLastSeenC } from './product-last-seen';
import { ProductProjectC } from './product-project';
import { emptyRoyaltiesHighChartData, RoyaltiesHighchartsDataC } from './royalties-high-chart-data';
import { SaleInC } from './sale-in';
import { TicketProductC } from './ticket-product';

const {
  News,
  UpcomingReleases,
  QualityCheck,
  ProductIncomplete,
  LastSeen,
  ChartEntries,
  ImportedRecently,
  ImportErrors,
  TicketProducts,
  TicketFixed,
  Playlisted,
  Unplaylisted,
  PlaylistClimbers,
  PlaylistFallers,
  TrendReportStreams,
  TrendReportDownloads,
  Royalties,
  PhysicalSaleIn,
  PhysicalInStore,
  ProjectsQC,
} = DashboardWidgetName;
export const WidgetCodecs = {
  News: getDashboardWidgetArrayType(News, NewsC),
  ChartEntries: getDashboardWidgetArrayType(ChartEntries, ChartEntryC),
  Playlisted: getDashboardWidgetArrayType(Playlisted, PlaylistEntryC),
  Unplaylisted: getDashboardWidgetArrayType(Unplaylisted, PlaylistEntryC),
  PlaylistClimbers: getDashboardWidgetArrayType(PlaylistClimbers, PlaylistEntryC),
  PlaylistFallers: getDashboardWidgetArrayType(PlaylistFallers, PlaylistEntryC),
  TicketFixed: getDashboardWidgetArrayType(TicketFixed, TicketProductC),
  TicketProducts: getDashboardWidgetArrayType(TicketProducts, TicketProductC),
  ImportedRecently: getDashboardWidgetArrayType(ImportedRecently, TicketProductC),
  UpcomingReleases: getDashboardWidgetArrayType(UpcomingReleases, TicketProductC),
  ProductIncomplete: getDashboardWidgetArrayType(ProductIncomplete, TicketProductC),
  QualityCheck: getDashboardWidgetArrayType(QualityCheck, TicketProductC),
  PhysicalSaleIn: getDashboardWidgetArrayType(PhysicalSaleIn, SaleInC),
  PhysicalInStore: getDashboardWidgetArrayType(PhysicalInStore, InStoreC),
  LastSeen: getDashboardWidgetArrayType(LastSeen, ProductLastSeenC),
  ProjectsQC: getDashboardWidgetArrayType(ProjectsQC, ProductProjectC),
  ImportErrors: getDashboardWidgetArrayType(ImportErrors, ProductImportC),
  TrendReportDownloads: getDashboardWidgetType(
    TrendReportDownloads,
    TrendReportsDownloadsC,
    emptyTrendReportsDownloads,
  ),
  TrendReportStreams: getDashboardWidgetType(TrendReportStreams, StreamsC, emptyTrendReportsStreams),
  Royalties: getDashboardWidgetType(Royalties, RoyaltiesHighchartsDataC, emptyRoyaltiesHighChartData),
};

const NamesC = fromNullable(t.array(DashboardWidgetNameC), []);

const dashboardProps = {
  ...WidgetCodecs,
  widgetOrder: NamesC,
  hidden: NamesC,
  update: t.union([t.null, DashboardWidgetNameC]),
};
export const DashboardC = t.type(dashboardProps);

export type Dashboard = t.TypeOf<typeof DashboardC>;

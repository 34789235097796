export const Status = (): Record<number, string> => ({
  1: $localize`:@@Dashboard.status.confirmed:Confirmed`,
  2: $localize`:@@Dashboard.status.edited:In Progress`,
  4: $localize`:@@Dashboard.status.created:Created`,
  5: $localize`:@@Dashboard.status.imported:Imported`,
  7: $localize`:@@Dashboard.status.inQC:In quality check`,
  10: $localize`:@@Dashboard.status.historized:Historcised`,
  11: $localize`:@@Dashboard.status.snapshot:Snapshot`,
  99: $localize`:@@Dashboard.status.archived:Archived`,
});

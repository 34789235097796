<div class="login-errors">
  <small class="error" i18n="@@login.error.unknown" *ngIf="status === 1">Nobody knows</small>
  <ng-container *ngIf="status === 401">
    <small *ngIf="message === 'error.login.wrong-credentials'" class="error" i18n="@@login.error.wrong-credentials"
      >Wrong credentials</small
    >
    <small *ngIf="message === 'error.login.incorrect-totp'" class="error" i18n="@@login.error.incorrect-totp"
      >Incorrect One Time Password</small
    >
    <small *ngIf="message === 'error.login.max-failed-attempts'" class="error" i18n="@@login.error.max-failed-attempts"
      >Your user account has been blocked, please contact your label manager.</small
    >
    <small *ngIf="message === 'error.login.password-expired'" class="error" i18n="@@login.error.password-expired"
      >Password must be updated! Redirecting....</small
    >
  </ng-container>
  <small class="error" i18n="@@login.error.403" *ngIf="status === 403">Access Denied</small>
  <small class="error" i18n="@@login.error.500" *ngIf="status >= 500">Bad Gateway</small>
</div>

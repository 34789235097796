import { createEnumCodec, hasProp, RetypeProperty } from '@dmb/core';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';
import { DashboardWidgetName, DashboardWidgetNameC } from './dashboard-widget-name';

const zeroOne = t.union([t.literal(0), t.literal(1)]);
//
// export interface DashboardWidget<Data> {
//   readonly items?: Data;
//   readonly name: DashboardWidgetName;
//   readonly colspan: 0 | 1;
//   readonly col: 0 | 1;
//   readonly collapsed: boolean;
//   readonly hidden: boolean;
//   readonly expanded: boolean;
//   readonly status: WidgetStatus;
// }

export type LoadedDashboardWidget<Data> = RetypeProperty<DashboardWidget<Exclude<Data, null>>, 'items', Data>;

export enum WidgetStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  MAINTENANCE = 'MAINTENANCE',
}

export const WidgetStatusC = createEnumCodec<WidgetStatus>(WidgetStatus, 'WidgetStatus');

const baseWidgetProps = {
  name: DashboardWidgetNameC,
  col: fromNullable(zeroOne, 0),
  colspan: t.union([t.literal(1), t.literal(2)]),
  collapsed: fromNullable(t.boolean, false),
  hidden: fromNullable(t.boolean, true),
  expanded: fromNullable(t.boolean, false),
  status: fromNullable(WidgetStatusC, WidgetStatus.LOADING),
  allowed: fromNullable(t.boolean, false),
};

export const DashboardWidgetUnknownC = t.type(baseWidgetProps);
export const InMaintenanceC = t.type({ items: t.literal('IN_MAINTENANCE') });

export type DashboardWidget<T> = t.TypeOf<typeof DashboardWidgetUnknownC> & { items: T };

export class DashboardWidgetType<T> extends t.Type<DashboardWidget<T>, object> {
  public constructor(name: DashboardWidgetName, e: t.Type<T, object>, defaultValue: T) {
    const colspan = name === DashboardWidgetName.Royalties ? 2 : 1;
    super(
      `DashboardWidget<${name}>`,
      (u): u is DashboardWidget<T> => {
        if (!DashboardWidgetUnknownC.is(u)) return false;
        if (u.name !== name) return false;
        if (!hasProp(u, 'items')) return true;
        return u.items == null || e.is(u.items);
      },
      (u, c) => {
        return pipe(
          DashboardWidgetUnknownC.validate(Object.assign({ colspan }, u), c),
          E.chain((decodedBase) => {
            const rawItems =
              hasProp(decodedBase, 'items') && decodedBase.items != null ? decodedBase.items : defaultValue;
            return pipe(
              e.validate(rawItems, c),
              E.map((items) => ({ ...decodedBase, ...{ items } })),
            );
          }),
        );
      },
      t.identity,
    );
  }
}

export const widgetIsLoaded = (w: DashboardWidget<unknown>) => w.status === WidgetStatus.LOADED;
export const getDashboardWidgetType = <T>(name: DashboardWidgetName, data: t.Type<T, object>, defaultValue: T) =>
  fromNullable(new DashboardWidgetType<T>(name, data, defaultValue), loadingWidget(name, 0, defaultValue));

export const getDashboardWidgetArrayType = <T>(name: DashboardWidgetName, data: t.Type<T, object>) =>
  getDashboardWidgetType(name, t.array(data), []);

export const loadingWidget = <T>(name: DashboardWidgetName, col: 0 | 1 = 0, emptyVal: T): DashboardWidget<T> => ({
  name,
  col,
  colspan: name === DashboardWidgetName.Royalties ? 2 : 1,
  collapsed: false,
  hidden: true,
  expanded: false,
  status: WidgetStatus.LOADING,
  allowed: false,
  items: emptyVal,
});

import { unsafeCoerce } from 'fp-ts/function';
import * as t from 'io-ts';

// export const partialRecord = <K extends Record<string, unknown>, T extends t.Any>(k: t.KeyofType<K>, type: T) =>
//   t.partial(map(() => type)(k.keys));
/**
 * see: [https://github.com/gcanti/io-ts/issues/429]
 * Solution posted by user 'anilanar'
 */
export type DictionaryC<D extends t.Mixed, C extends t.Mixed> = t.DictionaryType<
  D,
  C,
  {
    [K in t.TypeOf<D>]?: t.TypeOf<C>;
  },
  {
    [K in t.OutputOf<D>]?: t.OutputOf<C>;
  },
  unknown
>;

export const partialRecord = <D extends t.Mixed, C extends t.Mixed>(
  domain: D,
  codomain: C,
  name?: string,
): DictionaryC<D, C> => {
  return unsafeCoerce(t.record(t.union([domain, t.undefined]), codomain, name));
};

import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { smoothHeightAnimation, SvgIconComponent, VerticalPosDirective } from '@dmb/core';
import { pipe } from 'fp-ts/function';
import { BehaviorSubject, delay, merge, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { LayoutTranslation } from '../layout-translation';
import { MenuService } from '../menu.service';
import { SubmenuItemComponent } from '../submenu-item/submenu-item.component';

@Component({
  selector: 'dmb-top-level',
  templateUrl: './top-level.component.html',
  styleUrls: ['./top-level.component.scss'],
  animations: [smoothHeightAnimation],
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    RouterLinkActive,
    VerticalPosDirective,
    NgTemplateOutlet,
    RouterLink,
    MatIcon,
    NgIf,
    SvgIconComponent,
  ],
})
export class TopLevelComponent implements AfterContentInit, OnChanges, OnDestroy {
  private _enabled = new BehaviorSubject(false);
  private subscription = new Subscription();
  @Input() key?: keyof LayoutTranslation;
  @Input() path?: string;
  @Input() icon?: string;
  @Input() alwaysEnabled = false;
  @Input() noText = false;
  @HostBinding('class.warn') @Input() warn = false;
  @Input() custom = false;
  @Input() expanded = true; // for the search bar
  @Output() activate = new EventEmitter();
  @ContentChildren(SubmenuItemComponent) submenu?: QueryList<SubmenuItemComponent>;
  loaded = new Subject<boolean>();
  activeChild = pipe(
    merge(this.loaded, this.menuService.navEnded),
    delay(10),
    map(() => !!this.submenu?.find((e) => `/${e.path}` === this.ar.url)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  tr = LayoutTranslation.getTranslations();
  @HostBinding('class.activated') classActivated = false;
  @HostBinding('class.disabled') classDisabled = false;
  enabled = this._enabled.pipe(
    distinctUntilChanged(),
    mergeMap((v) => (v ? of(true) : this.menuService.menu.pipe(map((m) => !this.key || m.includes(this.key))))),
    tap((enabled) => (this.classDisabled = !enabled)),
  );

  get activated() {
    return this.classActivated;
  }

  get label() {
    return this.key ? this.tr[this.key] : '';
  }

  constructor(
    private menuService: MenuService,
    private ar: Router,
  ) {}

  ngAfterContentInit(): void {
    //navigation events are not triggered after load so we want an
    //initial value for the activeChild calculation
    setTimeout(() => this.loaded.next(true), 1);

    this.subscription.add(this.menuService.active.subscribe((v) => (this.classActivated = v === this.key)));
  }

  async toggleActivated($event?: Event) {
    $event?.preventDefault();
    if (!this.path && this.noText) {
      this.activate.emit($event);
      return;
    }
    this.menuService.setActive(this.activated ? null : this.key || null);
    $event?.stopPropagation();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(): void {
    this._enabled.next(this.alwaysEnabled);
  }
}

<mat-card class="reset-password-card">
  <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword($event)" id="resetForm">
    <dmb-card-header (closeModal)="close()">
      <span i18n="@@Login.password.forgotten.header">Forgotten Password</span>
    </dmb-card-header>
    <ng-container *ngIf="showForm">
      <mat-card-content>
        <p i18n="@@login.password.forgotten.text">
          Please enter your email address or user name associated with your account. The DMB will email instructions on
          how to reset your password.
        </p>
        <dmb-input
          [control]="resetPasswordForm.controls.userInput"
          id="userInput"
          label="email or username"
          i18n-label="@@login.password.forgotten.label"
        >
        </dmb-input>
        <mat-error *ngIf="sendingError"
          >There was an error when connecting to the server. Please try again later.</mat-error
        >
      </mat-card-content>
      <mat-card-actions>
        <dmb-button color="accent" design="raised" type="submit" [disabled]="!resetPasswordForm.valid">OK</dmb-button>
      </mat-card-actions>
    </ng-container>
    <mat-card-content *ngIf="!showForm">
      <p i18n="@@login.password.forgotten.success.text">
        Sollten ein Benutzerkonto zu dem eingegebenen Benutzernamen existieren, senden wir ein E-Mail mit weiteren
        Instruktionen um das Passwort zurückzusetzen.
      </p>
    </mat-card-content>
  </form>
</mat-card>

import { Directive, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { WindowRef } from '../window-ref';

@Directive({
  selector: '[verticalPos]',
  standalone: true,
})
export class VerticalPosDirective implements OnInit, OnDestroy {
  private win = this.winRef.nativeWindow;
  @HostBinding('class.vertical-pos-top') top = false;
  @HostBinding('class.vertical-pos-middle') middle = false;
  @HostBinding('class.vertical-pos-bottom') bottom = false;

  private sub = new Subscription();

  constructor(
    private el: ElementRef<HTMLElement>,
    private winRef: WindowRef,
  ) {}

  ngOnInit(): void {
    interval(500).subscribe(() => this.topMiddleBottom());
  }

  topMiddleBottom() {
    const perc = Math.trunc((this.el.nativeElement.getBoundingClientRect().top / this.win.innerHeight) * 100);
    this.top = perc < 33;
    this.bottom = perc > 66;
    this.middle = !(this.top || this.bottom);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

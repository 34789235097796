import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { DmbIcon } from './icon.model';

@Injectable({
  providedIn: 'root',
})
export class DmbIconService {
  private _svgPath = './assets/icons/icon_';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.init();
  }

  init() {
    Object.values(DmbIcon).forEach((name) => {
      this.matIconRegistry.addSvgIcon(
        name.replace('icon_', ''),
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this._svgPath}${name}.svg`),
      );
    });
  }
}

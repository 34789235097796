export const dateParse = (dt: string): Date | null => {
  if (dt.match(/^\d{6}$/)) {
    return new Date(parseInt(dt.slice(0, 4)), parseInt(dt.slice(-2)) - 1);
  }
  if (dt.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})[T|\s]([0-9]{2}):([0-9]{2}):([0-9]{2})/))
    return new Date(Date.parse(dt.replace(' ', 'T')));
  if (dt.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/)) return new Date(Date.parse(dt + 'T12:00:00'));
  const matchWithTime = dt.match(/^([0-9]{1,2})\.([0-2]{1,2})\.([0-9]{2,4}) ([0-2]{1,2}):([0-2]{1,2}):([0-2]{1,2})$/);
  if (matchWithTime)
    return new Date(
      Date.parse(
        `${matchWithTime[3]}-${matchWithTime[2]}-${matchWithTime[1]}T${matchWithTime[4]}:${matchWithTime[5]}:${matchWithTime[6]}`,
      ),
    );
  const match = dt.match(/^([0-9]{1,2})\.([0-2]{1,2})\.([0-9]{2,4})$/);
  if (match) return new Date(Date.parse(`${match[3]}-${pad(match[2])}-${pad(match[1])}T12:00:00`));
  return null;
};
const pad = (num: string) => `0${num}`.slice(-2);

import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgClass } from '@angular/common';
import { AfterContentInit, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import { ScrollableTriggerDirective } from '@dmb/core';
import { pipe } from 'fp-ts/function';
import { BehaviorSubject, concatWith, delay, of, Subscription } from 'rxjs';
import { map, mergeMap, shareReplay, startWith } from 'rxjs/operators';

import { SessionService } from '../../../services';
import { UserService } from '../../../user';
import { MenuService } from './menu.service';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { SubmenuItemComponent } from './submenu-item/submenu-item.component';
import { TopLevelComponent } from './top-level/top-level.component';

@Component({
  selector: 'dmb-combined-menu',
  templateUrl: './combined-menu.component.html',
  styleUrls: ['./combined-menu.component.scss'],
  animations: [
    trigger('addClassAfterDelay', [
      state('false', style({})), // Default state
      state('true', style({})), // State when the class 'test' is applied
      transition('false => true', [
        animate('1s'), // Delay of 1 second
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    NavHeaderComponent,
    TopLevelComponent,
    SubmenuItemComponent,
    ScrollableTriggerDirective,
  ],
})
export class CombinedMenuComponent implements AfterContentInit, OnDestroy {
  private resize = new BehaviorSubject(true);
  private resizer = new ResizeObserver(() => this.resize.next(true));
  @HostBinding('class.collapsed') collapsed = false;
  /**
   * this property is here to enable setting of classes after
   * this css animation is complete
   */
  // @HostBinding('class.transitioned') transitioned = false;
  @HostBinding('class.active') active = false;
  transitioned = pipe(
    this.menuService.mainMenuCollapsed,
    mergeMap(() => pipe(of(false), concatWith(pipe(of(true), delay(500))))),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  isImpersonated = this.sessionService.isImpersonated;
  realName$ = this.userService.user.pipe(map((user) => user.fullName));
  subscription = new Subscription();
  allowed = this.menuService.menu;
  logoutIcon = this.isImpersonated.pipe(
    map((v) => (v ? 'impersonate' : 'off')),
    startWith('off'),
  );

  constructor(
    private userService: UserService,
    private menuService: MenuService,
    private sessionService: SessionService,
    private el: ElementRef,
  ) {
    this.subscription.add(
      this.menuService.mainMenuCollapsed.subscribe((isCollapsed) => (this.collapsed = isCollapsed)),
    );
    this.subscription.add(this.menuService.active.pipe(map((a) => a != null)).subscribe((v) => (this.active = v)));
  }

  public async logout(): Promise<void> {
    await this.sessionService.logout();
  }

  ngOnDestroy(): void {
    this.resizer.disconnect();
    this.subscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.resizer.observe(this.el.nativeElement);
  }
}

import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { NumberFromString } from 'io-ts-types/lib/NumberFromString';

// Custom codec
export const AnyStringOrNumber = (defaultValue = 0) =>
  new t.Type<number, number, unknown>(
    'EmptyStringToZero',
    t.number.is,
    (u, c) =>
      pipe(
        t.number.validate(u, c),
        E.match(() => NumberFromString.validate(u, c), E.right),
        E.match((v) => (t.string.is(u) ? E.right(defaultValue) : E.left(v)), E.right),
        E.fold(
          () => t.failure(u, c),
          (e) => t.success(e),
        ),
      ),
    Number,
  );

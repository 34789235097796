import * as t from 'io-ts';
import { DashboardWidgetName } from '../../dashboard/model';

export type DmbRevisionStatusId =
  | 1 // confirmed
  | 2 // editing
  | 4 // created
  | 5 // imported
  | 7 // in-qc
  | 10 // historized
  | 11; // snapshot
export const DmbRevisionStatusIdC = t.union([
  t.literal(1),
  t.literal(2),
  t.literal(4),
  t.literal(5),
  t.literal(7),
  t.literal(10),
  t.literal(11),
]);

export enum DmbRevisionStatus {
  Confirmed = 1,
  Editing = 2,
  Created = 4,
  Imported = 5,
  QualityCheck = 7,
  Historized = 10,
  Snapshot = 11,
}

export type RevisionState = keyof typeof DashboardWidgetName;

export const revisionStateFromId = (id: DmbRevisionStatusId) => DmbRevisionStatus[id] as RevisionState;

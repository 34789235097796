import { iLiteral } from '@dmb/core';
import { TypeOf } from 'io-ts/Decoder';

export const DmbLanguageC = iLiteral('de', 'en', 'fr');
export type DmbLanguage = TypeOf<typeof DmbLanguageC>;
export const dmbLanguages = DmbLanguageC.values;

export const DmbLanguageI18n: () => Record<DmbLanguage, string> = () => ({
  de: $localize`:@@language.de:German`,
  en: $localize`:@@language.en:English`,
  fr: $localize`:@@language.fr:French`,
});

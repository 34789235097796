<form (submit)="submitSearch($event)" id="GlobalSearch">
  <dmb-input
    (blurred)="untouchedWhenEmpty()"
    [control]="searchString"
    [showErrors]="false"
    class="search-field"
    i18n-placeholder="@@Common.label.search-dots"
    placeholder="Search..."
  />
</form>

import { Lens } from 'monocle-ts';

export type LensesFor<T> = {
  [K in keyof T]: Lens<T, T[K]>;
};

export const makeLenses = <T>(arg: (keyof T)[]) => {
  const builder = Lens.fromProp<T>();
  return arg.reduce((r, k) => ({ ...r, [k]: builder(k) }), {} as LensesFor<T>);
};

/// <reference types="@angular/localize" />

import { environment } from '@dmb/dmb-ui';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app.config';
import * as Sentry from '@sentry/angular';
if (environment.sentryDsn != null) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['prod', /^https:\/\/kontor-new-media-gmbh.sentry\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
console.log(`environment is:  ${environment.sentryDsn}`);
bootstrapApplication(AppComponent, appConfig).catch((err) => console.log(err));

import { Injectable } from '@angular/core';
import { WindowRef } from '@dmb/core';

export type CoBrand = 'dmb' | 'dev' | 'local' | 'phonag' | 'playgroundmusic';
const coBrandFromHostname = (hostname: string): CoBrand => {
  if (hostname.includes('playgroundmusic')) return 'playgroundmusic';
  if (hostname.includes('phonag')) return 'phonag';
  if (hostname.includes('dev')) return 'dev';
  if (hostname.includes('.local')) return 'local';
  return 'dmb';
};
const favIcons: Record<CoBrand, string> = {
  dmb: 'dmb.ico',
  dev: 'dmb.dev.ico',
  local: 'dmb.dev.ico',
  phonag: 'dmb.ico',
  playgroundmusic: 'playgroundmusic.ico',
};

const loginLogoForCoBrand = (brand: CoBrand): string => {
  switch (brand) {
    case 'playgroundmusic':
      return 'logo_playgroundmusic_main';
    case 'phonag':
      return 'logo_phonag_main';
    case 'dmb':
      return 'logo';
    default:
      return 'logo-sw';
  }
};

const logoForCoBrand = (brand: CoBrand): string => {
  switch (brand) {
    case 'playgroundmusic':
      return 'logo_playgroundmusic';
    case 'phonag':
      return 'logo_phonag';
    case 'dmb':
      return 'logo';
    default:
      return 'logo-sw';
  }
};

@Injectable({ providedIn: 'root' })
export class CoBrandingService {
  coBrand = coBrandFromHostname(this.win.nativeWindow.location.hostname);
  favicon = favIcons[this.coBrand];
  loginLogo = loginLogoForCoBrand(this.coBrand);
  logo = logoForCoBrand(this.coBrand);
  isCoBrand = ['phonag', 'playgroundmusic'].includes(this.coBrand);

  constructor(private win: WindowRef) {}
}

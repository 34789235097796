<mat-card class="switch-client-card">
  <form id="selectLabel">
    <dmb-card-header (closeModal)="close()" class="switch-client-card-header">
      <span i18n="@@switch.client.change">Current:</span>
      <span *ngIf="selected">{{ selected.name }}</span>
    </dmb-card-header>

    <mat-card-content class="switch-label-card-content" *ngIf="selectables">
      <dmb-filter [listItems]="$any(selectables)" (dataChange)="select($event)" [selected]="selected"></dmb-filter>
    </mat-card-content>
  </form>
</mat-card>

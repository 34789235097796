<button
  mat-button
  [ngClass]="getClasses()"
  [class.fullWidth]="fullWidth"
  [color]="color"
  [type]="type"
  [disabled]="disabled"
>
  <ng-content></ng-content>
</button>

<div [ngClass]="{ 'icon-front': iconPlacement === 'front' }" class="input">
  <label *ngIf="label" [for]="control">{{ label }}<span *ngIf="required && showAsterisk">*</span></label>
  <svg-icon *ngIf="icon && iconPlacement === 'front'" [icon]="icon"></svg-icon>
  <input
    #theInput
    (blur)="blurred.next($event)"
    *ngIf="autoComplete"
    [formControl]="control"
    [matAutocomplete]="autoComplete"
    [name]="control"
    [ngClass]="{ required, disabled }"
    [placeholder]="placeholder"
    [type]="type"
  />
  <input
    #theInput
    (blur)="blurred.next($event)"
    *ngIf="!autoComplete"
    [autocomplete]="control"
    [formControl]="control"
    [name]="control"
    [ngClass]="{ required, disabled }"
    [placeholder]="placeholder"
    [type]="type"
  />
  <svg-icon *ngIf="icon && iconPlacement !== 'front'" [icon]="icon"></svg-icon>

  <ng-container *ngIf="showErrors && control.touched && control.dirty && control.errors">
    <span *ngFor="let error of nonStandardErrors(control.errors) | keyvalue" class="error message {{ error.key }}">{{
      error.value
    }}</span>
    <span *ngFor="let error of standardErrors(control.errors) | keyvalue" class="error message {{ error.key }}">{{
      error.value
    }}</span>
  </ng-container>
</div>

import { Route } from '@angular/router';
import { LoginComponent, MainComponent, StandardPageComponent } from './components';

const loadDashboard = () => import('./dashboard/dashboard.component').then((mod) => mod.DashboardComponent);
export const appRoutes: Route[] = [
  {
    path: 'album',
    loadChildren: () => import('./album/routes').then((mod) => mod.routes),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/routes').then((mod) => mod.routes),
  },
  {
    path: 'track',
    loadChildren: () => import('./track/routes').then((mod) => mod.routes),
    data: { type: 'track' },
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: StandardPageComponent,
        children: [
          {
            path: 'user',
            loadChildren: () => import('./user/user-routes').then((mod) => mod.userRoutes),
          },
          {
            path: 'dashboard',
            loadComponent: loadDashboard,
            title: 'dashboard',
          },
          {
            path: 'notification-center',
            loadComponent: () =>
              import('./notification-center/list/notification-center-list.component').then(
                (mod) => mod.NotificationCenterListComponent,
              ),
          },
          {
            path: 'page/faqs',
            loadChildren: () => import('./faq/faq-routes').then((mod) => mod.faqRoutes),
          },
          {
            path: 'faqs',
            loadChildren: () => import('./faq/faq-routes').then((mod) => mod.faqRoutes),
          },
          {
            path: 'home',
            redirectTo: 'dashboard',
          },
          {
            path: 'youtube',
            loadChildren: () => import('./youtube/routes').then((mod) => mod.routes),
          },
          {
            path: 'distribution-network',
            loadComponent: () =>
              import('./distribution-network/distribution-network.component').then(
                (mod) => mod.DistributionNetworkComponent,
              ),
          },
          {
            path: 'login',
            component: LoginComponent,
          },
          {
            path: '',
            pathMatch: 'full',
            loadComponent: loadDashboard,
            title: 'dashboard',
          },
        ],
      },
      {
        path: 'page',
        loadChildren: () => import('./iframe/iframe-routes').then((mod) => mod.iframeRoutes),
      },

      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
  },
];

import { DialogRef } from '@angular/cdk/dialog';
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCard, MatCardActions, MatCardContent } from '@angular/material/card';
import { MatError } from '@angular/material/form-field';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../../user';
import { CardHeaderComponent } from '../../card/header/card-header.component';
import { ButtonComponent, InputComponent } from '../../form';

@Component({
  selector: 'dmb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../modal.scss'],
  standalone: true,
  imports: [
    MatCard,
    ReactiveFormsModule,
    CardHeaderComponent,
    MatCardContent,
    InputComponent,
    MatError,
    MatCardActions,
    ButtonComponent,
    NgIf,
  ],
})
export class ResetPasswordComponent {
  @Output() closeModal = new EventEmitter<void>();
  resetPasswordForm = this.fb.nonNullable.group({
    userInput: ['', [Validators.required]],
  });

  sendingError = false;
  showForm = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    private userService: UserService,
  ) {}

  async resetPassword($event: Event) {
    $event.preventDefault();
    if (this.resetPasswordForm.invalid) return;
    try {
      await firstValueFrom(this.userService.requestPasswordReset(this.resetPasswordForm.getRawValue().userInput));
      this.showForm = false;
    } catch (_) {
      this.sendingError = true;
    }
  }

  close() {
    this.resetPasswordForm.reset();
    this.dialogRef.close();
    this.closeModal.emit();
  }
}

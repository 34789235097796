import { v4 as uuid } from 'uuid';
import { Command, CommandOperation } from './command';
import { MutateOperation } from './mutate-operation';

export class MutationCommand implements Command<MutateOperation<unknown>[]> {
  readonly id = uuid();
  readonly op: CommandOperation = 'patch';

  constructor(
    public readonly key: string,
    public readonly path: string,
    public readonly payload: MutateOperation<unknown>[] = [],
  ) {}
}

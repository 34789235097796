import * as t from 'io-ts';
import { ProductTypeC } from './product-type';

export const ProductLastSeenC = t.type({
  id: t.number,
  ean: t.string,
  type: ProductTypeC,
  title: t.string,
});
export type ProductLastSeen = t.TypeOf<typeof ProductLastSeenC>;

import { NullableDmbDateString } from '@dmb/core';
import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';
import { DmbRevisionStatusIdC } from '../../model';
import { ProductTypeC } from './product-type';

export const TicketProductC = t.type({
  id: t.number,
  code: fromNullable(t.string, ''),
  type: ProductTypeC,
  title: fromNullable(t.string, ''),
  salesStartDate: NullableDmbDateString,
  salesEndDate: NullableDmbDateString,
  revStateId: DmbRevisionStatusIdC,
  created: NullableDmbDateString,
});
export type TicketProduct = t.TypeOf<typeof TicketProductC>;

import * as t from 'io-ts';

export const DmbResponse = <Item extends t.Mixed>(itemType: Item) =>
  t.readonly(
    t.type({
      offset: t.number,
      limit: t.number,
      total: t.number,
      items: t.array(itemType),
    }),
  );

const DmbResponseMixed = DmbResponse(t.unknown);
type DmbResponseMixed = t.TypeOf<typeof DmbResponseMixed>;
export interface DmbResponse<T> extends DmbResponseMixed {
  items: T[];
}

export const Paginated = <Item extends t.Mixed>(itemType: Item) =>
  t.intersection([DmbResponse(itemType), t.type({ currentPage: t.readonly(t.number) })]);

export const toPaginationSettings = <T>(res: DmbResponse<T>): DmbResponse<T> & { currentPage: number } => ({
  ...res,
  currentPage: Math.floor((res.offset >= res.total ? res.total - res.limit : res.offset) / res.limit) + 1,
});

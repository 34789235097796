import * as t from 'io-ts';
import { ProductTypeC } from './product-type';

export const ChartEntryC = t.type({
  productId: t.number,
  type: ProductTypeC,
  productName: t.string,
  chartName: t.string,
  position: t.number,
  change: t.union([t.number, t.null]),
});

export type ChartEntry = t.TypeOf<typeof ChartEntryC>;

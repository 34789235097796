import { Routes } from '@angular/router';

export const userRoutes: Routes = [
  {
    path: 'settings',
    title: 'personal.account',
    loadComponent: () => import('./settings/settings.component').then((c) => c.SettingsComponent),
  },
  {
    path: 'sampler',
    loadComponent: () => import('./sampler/sampler.component').then((c) => c.SamplerComponent),
  },
  {
    path: '',
    redirectTo: 'settings',
    pathMatch: 'prefix',
  },
];

import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger, MatOptgroup, MatOption } from '@angular/material/autocomplete';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { mergeMap, Subject } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { AutocompleteGroup } from '../../../model/dmb-model/autocompleteGroup';
import { SvgIconComponent } from '@dmb/core';

export const iIncludes = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filterGroup =
  (groups: AutocompleteGroup[]) =>
  (value = ''): AutocompleteGroup[] => {
    if (!value) return groups;

    return groups
      .map((group) => ({ groupName: group.groupName, options: iIncludes(group.options, value) }))
      .filter((group) => group.options.length > 0);
  };

export const _filterOptions =
  (options: string[]) =>
  (value = ''): string[] => {
    if (!value) return options;

    return iIncludes(options, value).filter((option) => option.length > 1);
  };

@Component({
  selector: 'dmb-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  standalone: true,
  imports: [
    MatAutocomplete,
    MatIcon,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatOptgroup,
    NgIf,
    AsyncPipe,
    MatOption,
    NgForOf,
    MatIconButton,
    MatInput,
    SvgIconComponent,
  ],
})
export class AutocompleteComponent implements OnChanges {
  @Input() control: FormControl = new FormControl();
  @Input() isGrouped = false;
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() required = false;
  @Input() icon = '';
  @Input() autocompleteGroups: AutocompleteGroup[] = [];
  @Input() options: string[] = [];
  @Output() optionSelected = new EventEmitter();
  theme = 'standard';
  autocompleteGroupsInput = new Subject<AutocompleteGroup[]>();
  groupOptions = this.autocompleteGroupsInput.pipe(
    mergeMap((groups) =>
      this.control.valueChanges.pipe(
        map(_filterGroup(groups)),
        startWith(groups),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    ),
  );
  optionsInput = new Subject<string[]>();
  obsOptions = this.optionsInput.pipe(
    mergeMap((options) =>
      this.control.valueChanges.pipe(
        map(_filterOptions(options)),
        startWith(options),
        shareReplay({ bufferSize: 1, refCount: true }),
      ),
    ),
  );

  constructor() {
    this.theme = localStorage.getItem('theme') || 'standard';
  }

  ngOnChanges(): void {
    this.autocompleteGroupsInput.next(this.autocompleteGroups);
    this.optionsInput.next(this.options);
  }

  clearInput() {
    this.control.setValue('');
  }

  getInput() {
    return this.control.value;
  }
}

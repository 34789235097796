import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/ReadonlyArray';
import { toLowerCase } from 'fp-ts/string';

import * as t from 'io-ts';

export const codecFromArray = <T extends string | number>(i: [T, ...T[]]): [t.KeyofC<Record<T, T>>, [T, ...T[]]] => {
  return [t.keyof(A.reduce({} as Record<T, T>, (r, l: T) => ({ ...r, [l]: l }))(i)), [...i]];
};

export class LiteralsType<A, O = A, I = unknown> extends t.Type<A, O, I> {
  constructor(
    public values: readonly [string, ...Array<string>],
    name: string,
    is: t.Is<A>,
    validate: t.Validate<unknown, A>,
    encode: t.Encode<A, O>,
  ) {
    super(name, is, validate, encode);
  }

  addPreprocessor(preprocessor: (i: unknown) => I) {
    return new LiteralsType(
      this.values,
      this.name,
      this.is,
      (i: unknown, context) => this.validate(preprocessor(i), context),
      this.encode,
    );
  }
}
export const lcLiteral = <A extends readonly [string, ...Array<string>]>(...values: [string, ...string[]] & A) => {
  return iLiteral(...(A.map(toLowerCase)(values) as [string, ...string[]]));
};
export const iLiteral = <A extends readonly [string, ...Array<string>]>(...values: [string, ...string[]] & A) =>
  new LiteralsType<A[number]>(
    values,
    values.join(' | '),
    (i): i is A[number] => t.string.is(i) && values.includes(i as string),
    (i, context) =>
      pipe(
        t.string.decode(i),
        E.chain((s) =>
          pipe(
            values,
            A.findIndex((value) => value.toLowerCase() === s.toLowerCase()),
            O.fold(
              () => t.failure(s, context),
              (i) => t.success(values[i]),
            ),
          ),
        ),
      ),
    t.string.encode,
  );

import { AnyStringOrNumber } from '@dmb/core';
import * as E from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';
import { PathReporter } from 'io-ts/PathReporter';
import { ProductTypeC } from '../../dashboard/model';
import { DmbFontSizeC } from '../dmb-font';
import { DmbLanguageC } from '../dmb-language';
import { DmbClient, DmbLabel, DmbThemeC } from '../dmb-model';
import { CommsObjectC } from './comms-type';

export type NewsletterItem = {
  internal_name: string;
  subscribed: boolean;
};
export type NewsletterItemWithLabel = NewsletterItem & { label: string };

export type NewsletterResponse = {
  items: NewsletterItem[];
  limit: number;
};
export type AccountProtection = {
  totpEnabled: boolean;
  emailForLoginFromNewDevice: boolean;
};

export const SearchPrefsC = t.partial({
  'search:defaultProduct': fromNullable(ProductTypeC, 'Album'),
  'search:initialSearchLimited': fromNullable(AnyStringOrNumber(10), 10),
});

export const UserPreferencesC = t.intersection([
  t.type({
    theme: fromNullable(DmbThemeC, 'standard'),
    fontSize: fromNullable(DmbFontSizeC, 'regular'),
    mainMenuCollapsed: fromNullable(t.boolean, false),
  }),
  SearchPrefsC,
]);

export type UserPreferences = t.TypeOf<typeof UserPreferencesC>;
const emptyPrefs: UserPreferences = { theme: 'standard', fontSize: 'regular', mainMenuCollapsed: false };

export const UserSessionC = t.type({
  selectedClientId: t.number,
  selectedLabelId: t.union([t.number, t.null]),
  isImpersonated: t.boolean,
  privileges: fromNullable(t.array(t.string), []),
});

export type UserSession = t.TypeOf<typeof UserSessionC>;
export const hasPrivilege = (privilege: string) => (session: UserSession) => session.privileges.includes(privilege);

export type SessionLabelOption = {
  selectable: boolean;
  name: string;
  label: DmbLabel | null;
};

export type Selectable = {
  c: DmbClient;
  level: number;
};

export type SelectableMapped = { parent?: number; name: string; level?: number };
export const UserProfileC = t.type({
  username: fromNullable(t.string, 'anon'),
  fullName: fromNullable(t.string, 'Guest User'),
  email: fromNullable(t.string, ''),
  clientId: fromNullable(t.number, 0),
  authenticated: fromNullable(t.boolean, false),
  language: fromNullable(DmbLanguageC, 'de'),
  emailForLoginFromNewDevice: fromNullable(t.boolean, false),
  totpEnabled: fromNullable(t.boolean, false),
  comms: fromNullable(t.array(CommsObjectC), []),
  preferences: fromNullable(UserPreferencesC, emptyPrefs),
});

export const anon: UserProfile = {
  username: 'anon',
  fullName: 'Anonymous User',
  email: '',
  clientId: 0,
  authenticated: false,
  language: 'de',
  emailForLoginFromNewDevice: false,
  totpEnabled: false,
  comms: [],
  preferences: {
    theme: 'standard',
    fontSize: 'regular',
    mainMenuCollapsed: false,
  },
};
export type UserProfile = t.TypeOf<typeof UserProfileC>;
export const decodeToAuthenticated: (i: unknown) => UserProfile = flow(
  (up) => Object.assign({}, up, { authenticated: true }),
  UserProfileC.decode,
  (e) => {
    if (E.isLeft(e)) console.error(PathReporter.report(e));
    return e;
  },
  E.getOrElse(() => anon),
);

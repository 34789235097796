import { forwardRef, Injectable, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken('Window');

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => BrowserWindowRef),
})
export abstract class WindowRef {
  abstract get nativeWindow(): Window;
}

export const windowProvider = { provide: WINDOW, useValue: window };

@Injectable({ providedIn: 'root' })
export class BrowserWindowRef extends WindowRef {
  get nativeWindow(): Window {
    return window;
  }
}

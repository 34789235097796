import { map, merge, Observable, scan, startWith, Subject, Subscribable, Subscriber } from 'rxjs';

type ResultMap<W> = (state: W) => W;
type Appender<T, W> = (value: T) => ResultMap<W>;

export interface ObservableCollection<T, W> extends Subscribable<W> {
  next(value: T): void;

  overwrite(value: W): void;

  reset(): void;

  empty(): void;
}

export class AppendableObservable<T, W> extends Observable<W> implements ObservableCollection<T, W> {
  private add = new Subject<T>();
  private resetter = new Subject<W>();

  protected list: Observable<W> = merge(
    this.add.pipe(map(this.appender)),
    this.resetter.pipe(
      map(
        (state: W): ResultMap<W> =>
          () =>
            state,
      ),
    ),
  ).pipe(
    scan((state: W, appender: ResultMap<W>) => appender(state), this.start),
    startWith(this.start),
  );

  constructor(
    private appender: Appender<T, W>,
    private start: W,
    private emptyValue: W,
  ) {
    super((subscriber: Subscriber<W>) => {
      const subscription = this.list.subscribe(subscriber);
      return () => subscription.unsubscribe();
    });
  }

  next(value: T) {
    this.add.next(value);
  }

  reset() {
    this.resetter.next(this.start);
  }

  empty() {
    this.resetter.next(this.emptyValue);
  }

  overwrite(value: W) {
    this.resetter.next(value);
  }

  complete() {
    this.add.complete();
  }
}

export const getAppendable = <T>(startWith: T[] = []) =>
  new AppendableObservable<T, T[]>((v) => (state) => [...state, v], startWith, []);

export const getToggleSetObservable = <T>(startWith: Set<T> = new Set<T>()) =>
  new AppendableObservable<T, Set<T>>(
    (v) => (state) => {
      const res = new Set(state);
      if (!res.delete(v)) res.add(v);
      return res;
    },
    startWith,
    new Set(),
  );

import { DmbDateString } from '@dmb/core';
import * as t from 'io-ts';
import { DmbRevisionStatusIdC } from '../../model';
import { ProductTypeC } from './product-type';

export const InStoreC = t.type({
  /**
   * @format any
   */
  productId: t.number,
  ean: t.union([t.string, t.null]),
  type: ProductTypeC,
  title: t.string,
  revStateId: DmbRevisionStatusIdC,
  alreadyInSAP: t.boolean,
  inStoreDate: DmbDateString,
});

export type InStore = t.TypeOf<typeof InStoreC>;

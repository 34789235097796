import { Directive, ElementRef, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { filter, finalize, fromEvent, interval, takeUntil } from 'rxjs';

const down = (el: HTMLElement) => el.scrollTop + el.clientHeight < el.scrollHeight - 5;
const up = (el: HTMLElement) => el.scrollTop > 0;

@Directive({
  selector: '[scrollableTrigger]',
  standalone: true,
})
export class ScrollableTriggerDirective implements OnDestroy {
  private sub = interval(500).subscribe(() => this.onscroll());
  @HostBinding('class.scrollUp') scrollUp = false;
  @HostBinding('class.scrollDown') scrollDown = false;
  @HostBinding('class.grabbing') grabbing = false;

  constructor(private el: ElementRef) {}

  @HostListener('mousedown', ['$event']) onclick(click: PointerEvent) {
    if (!(this.scrollDown || this.scrollUp)) return;
    this.grabbing = true;

    fromEvent(document, 'mousemove')
      .pipe(
        filter((ev): ev is MouseEvent => ev instanceof MouseEvent),
        takeUntil(fromEvent(document, 'mouseup')),
        finalize(() => (this.grabbing = false)),
      )
      .subscribe((e) => {
        const newTop = this.el.nativeElement.scrollTop - (e.clientY - click.clientY);
        this.el.nativeElement.scrollTop = newTop < 0 ? 0 : newTop;
      });
  }

  @HostListener('scroll', ['$event']) onscroll() {
    this.scrollUp = up(this.el.nativeElement);
    this.scrollDown = down(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

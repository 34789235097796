import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ButtonVariant } from '@angular/material/button/testing';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'dmb-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, NgClass],
})
export class ButtonComponent {
  @Input() color: ThemePalette = 'accent';
  @Input() type = 'submit';
  @Input() disabled: BooleanInput = false;
  @Input() design: ButtonVariant = 'raised';
  @Input() fullWidth: BooleanInput = false;

  getClasses() {
    return `${this.design ? 'mat-mdc-' + this.design + '-button' : ''}`;
  }
}

import { AnyStringOrNumber, DmbDateString } from '@dmb/core';
import * as t from 'io-ts';
import { ProductTypeC } from './product-type';

export const ProductImportC = t.type({
  id: AnyStringOrNumber(0),
  code: t.string,
  type: ProductTypeC,
  message: t.string,
  importDate: DmbDateString,
});
export type ProductImport = t.TypeOf<typeof ProductImportC>;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  constructor(private _http: HttpClient) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public options(path: string): Observable<any> {
    return this._http.options(path, {}).pipe(
      switchMap(() => of(true)),
      catchError((err) => {
        if (err.status !== 403) return throwError(err);
        return of(false);
      }),
    );
  }
}

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { flow } from 'fp-ts/function';

export const validatorWrapper = (
  v: ValidatorFn,
  message: (e: ValidationErrors) => Record<string, string>,
): ValidatorFn =>
  flow(v, (r) => {
    if (!r) return null;
    return message(r);
  });

export const simpleMessage =
  (v: ValidatorFn) =>
  (field: string, message: string): ValidatorFn =>
    validatorWrapper(v, () => ({ [field]: message }));

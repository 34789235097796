import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { SvgIconComponent } from '@dmb/core';

@Component({
  selector: 'dmb-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss'],
  standalone: true,
  imports: [MatCardModule, MatIconModule, MatTooltipModule, NgIf, SvgIconComponent],
})
export class CardHeaderComponent {
  @Input() title = '';
  @Output() closeModal = new EventEmitter<void>();
  theme = 'standard';

  constructor() {
    this.theme = localStorage.getItem('theme') || 'standard';
  }

  close() {
    this.closeModal.emit();
  }
}

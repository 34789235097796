import { AfterContentInit, Component, HostBinding, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import {
  CoBrandingService,
  DmbFontSize,
  DmbFontSizes,
  dmbThemes,
  I18n,
  isFontSize,
  StorageService,
  UserService,
} from '@dmb/dmb-ui';
import { LazySpriteSheetComponent } from '@dmb/core';
import { distinctUntilChanged, filter, mergeMap, startWith, Subscription, tap } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'dmb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LazySpriteSheetComponent, RouterOutlet, AsyncPipe],
  providers: [UserService, StorageService, CoBrandingService],
})
export class AppComponent implements OnDestroy, AfterContentInit {
  subscription = new Subscription();
  spriteSheet: SafeHtml = '';

  @HostBinding('class') classes = '';

  constructor(
    private userService: UserService,
    private storage: StorageService,
    @Inject(LOCALE_ID) private localeId: string,
    private i18n: I18n,
    private cobrandingService: CoBrandingService,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
  ) {
    this.subscription.add(
      userService.fontSize
        .pipe(
          filter((f) => !!f),
          startWith(this.getDefaultFontSize()),
        )
        .subscribe((fs) => this.setFontSize(fs)),
    );
    this.subscription.add(this.theme.subscribe((t) => this.switchTheme(t)));
    this.subscription.add(
      this.userService.language
        .pipe(
          mergeMap(async (l) => {
            if (l != this.localeId) {
              await this.i18n.setLocale(l);
              location.reload();
            }
          }),
        )
        .subscribe(),
    );
  }

  ngAfterContentInit(): void {
    const link: HTMLLinkElement = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);

    link.href = `/assets/favicons/${this.cobrandingService.favicon}`;
    this.getSvgSprite();
  }

  theme = this.userService.theme.pipe(
    startWith(this.getDefaultTheme()),
    distinctUntilChanged(),
    tap((t) => {
      this.storeTheme(t);
    }),
  );

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getSvgSprite() {
    const link = `/assets/dmb-icon-sprite.svg`;
    this.httpClient
      .get(link, { responseType: 'text' })
      .subscribe((val) => (this.spriteSheet = this.sanitizer.bypassSecurityTrustHtml(val)));
  }

  getDefaultFontSize(): DmbFontSize {
    const stored = this.storage.localStorage.getItem('fontSize');
    return isFontSize(stored) ? stored : 'regular';
  }

  getDefaultTheme(): string {
    const stored = this.storage.localStorage.getItem('theme');
    return stored ? stored : 'standard';
  }

  setFontSize(size: DmbFontSize) {
    this.storage.localStorage.setItem('fontSize', size);
    document.documentElement.style.setProperty('--app-base-size', `var(--app-base-size-${size})`);
    DmbFontSizes.forEach((fs) => document.documentElement.classList.toggle(fs, false));
    document.documentElement.classList.toggle(size, true);
  }

  switchTheme(theme: string) {
    const fName = `theme-${theme}.css`;
    const current = document?.getElementById('theme')?.getAttribute('href');
    if (current !== `theme-${theme}.css`) document?.getElementById('theme')?.setAttribute('href', fName);
    dmbThemes.forEach((th) => document.documentElement.classList.toggle(`theme-${th}`, false));
    document.documentElement.classList.toggle(`theme-${theme}`, true);
  }

  private storeTheme(t: string) {
    this.storage.localStorage.setItem('theme', t);
  }
}

type SortResponse = -1 | 0 | 1;
const propSort =
  <T extends Record<K, string | undefined | null>, K extends keyof T>(k: K, reverse = false) =>
  (a: T, b: T): SortResponse => {
    const vals = [a[k] || '', b[k] || ''];
    if (reverse) vals.reverse();
    const [aVal, bVal] = vals;
    if (aVal.toLowerCase() < bVal.toLowerCase()) return -1;
    return aVal.toLowerCase() > bVal.toLowerCase() ? 1 : 0;
  };

type SortByStringProp = <T extends Record<K, string | undefined | null>, K extends keyof T>(list: T[], k: K) => T[];
const sortByProp: (reverse: boolean) => SortByStringProp = (reverse: boolean) => (list, k) => {
  const newList = [...list];
  return newList.sort(propSort(k, reverse));
};

export const sortByStringProp: SortByStringProp = sortByProp(false);
export const sortByStringPropReverse: SortByStringProp = sortByProp(true);
export const sortMap = (map: object) => {
  const entries = Object.entries(map);
  entries.sort((a, b) => (a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0));
  return entries.reduce((r, e) => ({ ...r, [e[0]]: e[1] }), {});
};

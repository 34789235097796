import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'dmb-standard-page',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./standard-page.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class StandardPageComponent {}

import { Injectable, OnDestroy, RendererFactory2 } from '@angular/core';
import { hasProp } from '@dmb/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { DmbFontSize } from '../model';
import { UserService } from '../user';

export type DmbFrameEventType =
  | 'frame-ready'
  | 'frame-unload'
  | 'font-size-update'
  | 'handshake-response'
  | 'height-update'
  | 'overlay-active'
  | 'overlay-request'
  | 'overlay-status'
  | 'selected-client-update'
  | 'page-title'
  | 'impersonation-request'
  | 'v4-navigation';

export interface DmbFrameEvent<T> {
  eventType: DmbFrameEventType;
  payload: T;
}

export type DmbFrameIsReady = DmbFrameEvent<{ url: string; path: string }>;
export type DmbFrameUnload = DmbFrameEvent<boolean>;
export type DmbV4Navigation = DmbFrameEvent<string>;
export type DmbFrameHeight = DmbFrameEvent<number>;
export type DmbOverlayActive = DmbFrameEvent<boolean>;
export type DmbFontSizeUpdate = DmbFrameEvent<DmbFontSize>;
export type DmbPageTitle = DmbFrameEvent<string>;
export type DmbImpersonation = DmbFrameEvent<number>;

export const isFrameEvent =
  <T>(prop: DmbFrameEventType) =>
  (e: unknown): e is T =>
    hasProp(e, 'eventType') && typeof e.eventType === 'string' && e.eventType === prop;

export const [
  isFrameIsReady,
  isFrameUnload,
  isOverlayActive,
  isFrameHeight,
  isV4Navigation,
  isPageTitle,
  isImpersonation,
] = [
  isFrameEvent<DmbFrameIsReady>('frame-ready'),
  isFrameEvent<DmbFrameUnload>('frame-unload'),
  isFrameEvent<DmbOverlayActive>('overlay-active'),
  isFrameEvent<DmbFrameHeight>('height-update'),
  isFrameEvent<DmbV4Navigation>('v4-navigation'),
  isFrameEvent<DmbPageTitle>('page-title'),
  isFrameEvent<DmbImpersonation>('impersonation-request'),
];

@Injectable({
  providedIn: 'root',
})
export class FrameService implements OnDestroy {
  messages = new Subject<DmbFrameEvent<unknown>>();
  private subscription = new Subscription();

  frameAvailable = this.messages.pipe(
    filter((e) => isFrameIsReady(e) || isFrameUnload(e)),
    map(isFrameIsReady),
    startWith(false),
  );

  outbox = new Subject<DmbFrameEvent<unknown>>();

  constructor(
    rendererFactory: RendererFactory2,
    private prefs: UserService,
  ) {
    const renderer = rendererFactory.createRenderer(null, null);
    renderer.listen('window', 'message', (m: MessageEvent) => this.messages.next(m.data));

    this.listenForFontSizeChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  postMessage<T>(eventType: DmbFrameEventType, payload: T | null = null) {
    this.outbox.next({ eventType, payload });
  }

  listenForFontSizeChanges() {
    this.subscription.add(
      this.prefs.fontSize
        .pipe(
          map(
            (payload): DmbFontSizeUpdate => ({
              eventType: 'font-size-update',
              payload,
            }),
          ),
        )
        .subscribe((e) => this.outbox.next(e)),
    );
  }
}

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';

import { LayoutTranslation } from '../components/main/combined-menu/layout-translation';

@Injectable()
export class DmbTitleStrategy extends TitleStrategy {
  private translationLookup = () => pipe(LayoutTranslation.getTranslations(), R.toEntries, R.fromEntries);

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot) {
    this.title.setTitle(
      pipe(
        // get the title from the router config, or the top level path from the url
        O.fromNullable(this.buildTitle(snapshot) ?? this.fromUrl(snapshot.url)),
        // set if
        O.chain((k) => pipe(this.translationLookup(), R.lookup(k))),
        O.fold(
          () => 'DMB',
          (v) => `${v} | DMB`,
        ),
      ),
    );
  }

  private fromUrl(url: string) {
    const match = url.match(/^\/([^/]+)/);
    return match ? match[1] : null;
  }
}

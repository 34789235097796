<div class="nav-header">
  <svg-icon class="company-logo {{ coBrand }}" [icon]="'icon_' + logo"></svg-icon>
  <dmb-switch-client></dmb-switch-client>
  <div class="menu-toggle" (click)="toggleMainMenu()">
    <svg-icon [icon]="'icon_' + (toggleIcon | async)"></svg-icon>
  </div>
</div>
<div class="search" [ngClass]="{ 'open-search': searchActive }">
  <div class="search-icon" (click)="expandSearch($event)"><svg-icon icon="icon_search"></svg-icon></div>
  <dmb-search (searching)="handleSearch($event)" (click)="$event.stopPropagation()"></dmb-search>
</div>

import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionLabelOption } from '../../../model';
import { ClientService, SessionService } from '../../../services';
import { ModalService } from '../modal.service';
import { SelectClientComponent } from './select-client/select-client.component';
import { SelectLabelComponent } from './select-label/select-label.component';
import { SvgIconComponent } from '@dmb/core';

@Component({
  selector: 'dmb-switch-client',
  templateUrl: './switch-client.component.html',
  styleUrls: ['./switch-client.component.scss'],
  standalone: true,
  imports: [NgClass, AsyncPipe, NgIf, SvgIconComponent],
})
export class SwitchClientComponent {
  client = this.sessionService.client;
  clients = this.clientService.clients.pipe(map((c) => Object.values(c)));
  clientSelectable = this.clients.pipe(map((c) => c.length > 0));
  labels = this.sessionService.labels;
  labelOption = this.sessionService.labelOption;

  constructor(
    private sessionService: SessionService,
    private modalService: ModalService,
    private clientService: ClientService,
  ) {}

  selectLabel(lbl: SessionLabelOption) {
    if (!lbl.selectable) return;
    return this.modalService.openDialog(SelectLabelComponent);
  }

  getClasses(lbl: SessionLabelOption) {
    return { selectable: lbl.selectable, 'animated-label': lbl.name.length > 22 };
  }

  async selectClient() {
    if (!(await firstValueFrom(this.clientSelectable))) return;

    return this.modalService.openDialog(SelectClientComponent);
  }
}

<ng-container *ngIf="enabled | async">
  <div
    class="label"
    routerLinkActive="activeLink"
    [ngClass]="{ activeChild: activeChild | async, warn, expanded: !expanded }"
    verticalPos
  >
    <a class="wrapper" draggable="false" *ngIf="path && !submenu.children.length" [routerLink]="path">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <div (click)="toggleActivated($event)" class="wrapper" *ngIf="!path || submenu.children.length">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </div>

  <div class="submenu" #submenu [ngClass]="{ activated }">
    <ng-content select="dmb-submenu-item"></ng-content>
  </div>

  <ng-template #content>
    <div class="menu-icon">
      <svg-icon *ngIf="icon" [icon]="'icon_' + icon"></svg-icon>
    </div>
    <div class="text-label">
      <div *ngIf="!noText">
        {{ label }}
      </div>
      <ng-container *ngIf="noText">
        <ng-content></ng-content>
      </ng-container>
    </div>
    <svg-icon *ngIf="submenu.children.length" icon="icon_arrow_right" class="expander"></svg-icon>
  </ng-template>
</ng-container>

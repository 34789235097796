import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { pipe } from 'fp-ts/function';
import { BehaviorSubject, firstValueFrom, merge, Observable, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { SessionService } from '../../../services';

import { UserService } from '../../../user';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private sessionService: SessionService,
    private router: Router,
  ) {}

  navEnded = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private mainMenuUrl = '/api/v4/public/navigation/combined';

  menu = pipe(
    this.sessionService.selectedClientId,
    distinctUntilChanged(),
    switchMap(() => this.http.get<string[]>(this.mainMenuUrl)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  private userMenuState: Observable<boolean> = this.userService.preferences.pipe(
    map((p) => p.mainMenuCollapsed === true),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  localCollapse = new Subject<boolean>(); // this just gives a more responsive update than waiting for the user update.
  mainMenuCollapsed = merge(this.userMenuState, this.localCollapse);

  private _active = new BehaviorSubject<string | null>(null);
  active: Observable<string | null> = this._active.pipe(
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  setActive(key: string | null) {
    this._active.next(key);
  }

  async toggleMenu() {
    const newValue = !(await firstValueFrom(this.userMenuState));
    this.userService.sendUpdate({ 'preferences.mainMenuCollapsed': newValue }, 'MenuToggle');
    this.localCollapse.next(newValue);
  }
}

import { AsyncPipe, NgIf } from '@angular/common';
import { Component, HostBinding, HostListener } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../user';
import { SpinnerComponent } from '../form/spinner/spinner.component';
import { LoginComponent } from '../login';
import { CombinedMenuComponent, MenuService } from './combined-menu';

@Component({
  selector: 'dmb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [LoginComponent, AsyncPipe, NgIf, RouterOutlet, SpinnerComponent, CombinedMenuComponent],
})
export class MainComponent {
  subscriptions = new Subscription();

  @HostBinding('class.collapsed') collapsed = false;
  showLogin = this.userService.user.pipe(map((u) => u?.authenticated === false));

  constructor(
    private userService: UserService,
    private menuService: MenuService,
  ) {
    this.subscriptions.add(this.menuService.mainMenuCollapsed.subscribe((v) => (this.collapsed = v)));
  }

  @HostListener('click') disableActivated() {
    this.menuService.setActive(null);
  }
}

import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { pipe } from 'fp-ts/function';
import { Subject } from 'rxjs';
import { mergeMap, shareReplay, startWith, tap } from 'rxjs/operators';
import { LoadingService } from '../../services';

@Component({
  selector: 'dmb-inline-spinner',
  standalone: true,
  imports: [NgForOf, AsyncPipe, NgClass],
  templateUrl: './inline-spinner.component.html',
  styleUrl: './inline-spinner.component.scss',
})
export class InlineSpinnerComponent implements OnDestroy {
  private _loader$ = new Subject<LoadingService>();
  protected readonly Array: ArrayConstructor = Array;
  @HostBinding('class.enabled') enabled = false;
  loading = pipe(
    this._loader$,
    mergeMap((l) => l.isLoading),
    startWith(false),
    tap((v) => (this.enabled = v)),
    shareReplay(1),
  );
  sub = this.loading.subscribe();

  @Input() set loader(l: LoadingService) {
    this._loader$.next(l);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

export function flattenJson(obj: Record<string | number, unknown> = {}, parentKey?: string): Record<string, Scalar> {
  const root = parentKey ? `${parentKey}.` : '';
  return Object.entries(obj).reduce<Record<string, Scalar>>(
    (r, [k, v]) => ({
      ...r,
      ...(isScalar(v) ? { [`${root}${k}`]: v } : isRecord(v) ? flattenJson(v, `${root}${k}`) : {}),
    }),
    {},
  );
}

type Scalar = string | number | boolean | undefined;
const isRecord = (val?: unknown): val is Record<string | number, unknown> => val != null && typeof val === 'object';
const isScalar = (val?: unknown): val is Scalar => val == null || typeof val !== 'object';

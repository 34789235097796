import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import * as td from 'io-ts-types';
import { dateParse } from '../date-parse';

export const DmbDateString = new t.Type<Date, string, unknown>(
  'DmbDateString',
  (i): i is Date => i instanceof Date,
  (u, c) => {
    if (td.date.is(u)) return t.success(u);
    return pipe(
      t.string.validate(u, c),
      E.map(dateParse),
      E.chain((d) => (!d || isNaN(d.getTime()) ? t.failure(u, c) : t.success(d))),
    );
  },
  (v) => v.toISOString(),
);

export const NullableDmbDateString = t.union([t.null, DmbDateString]);

import * as t from 'io-ts';
import { DmbRevisionStatusIdC } from '../../model';
import { ProductTypeC } from './product-type';

export const SaleInC = t.type({
  productId: t.number,
  ean: t.union([t.string, t.null]),
  type: ProductTypeC,
  title: t.string,
  revStateId: DmbRevisionStatusIdC,
  alreadyInSAP: t.boolean,
  saleInDate: t.string,
});
export type SaleIn = t.TypeOf<typeof SaleInC>;
// export interface SaleIn {
//   readonly productId: string;
//   readonly ean: string;
//   readonly type: ProductType;
//   readonly title: string;
//   readonly revStateId: DmbRevisionStatusId;
//   readonly alreadyInSAP: boolean;
//   readonly saleInDate: string;
// }

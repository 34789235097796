import { Injectable } from '@angular/core';

declare global {
  const Matomo: MatomoInterface;

  interface MatomoInterface {
    getTracker: (url: string, siteId: string) => MatomoTracker;
  }

  interface MatomoTracker {
    trackPageView: (title?: string) => void;
    trackEvent: (category: string, action: string, name?: string, value?: number) => void;
    requireCookieConsent: () => void;
  }
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private tracker: MatomoTracker | undefined = undefined;
  private events: (string | number | undefined)[][] = [];

  /**
   * Initialize the tracking data. Tracking is cookie-less so you may start promptly
   * @param url The URL the analytics data are sent to
   * @param siteId The ID of the site the data belongs to
   */
  initializeTracking(url: string, siteId: number): void {
    this.tracker = undefined;
    this.events = [];
    const id = 'AnalyticsScript';
    document.getElementById(id)?.remove();
    const firstScript = document.getElementsByTagName('script')[0];
    const newScript = document.createElement('script');
    newScript.id = id;
    newScript.async = true;
    newScript.src = `//${url}/matomo.js`;
    newScript.onload = () => {
      this.tracker = Matomo.getTracker(`//${url}/matomo.php`, siteId.toString());
      this.tracker.requireCookieConsent();
      this.tracker.trackPageView();
      this.events.forEach((a) =>
        this.tracker?.trackEvent(a[0] as string, a[1] as string, a[2] as string, a[3] as number | undefined),
      );
      this.events = [];
    };
    firstScript.parentNode?.insertBefore(newScript, firstScript);
  }

  /**
   * Logs an event. The event is ignored until both enableTracking and initializeTracking were called.
   * @param category
   * @param action
   * @param name
   * @param value
   */
  logEvent(category: string, action: string, name: string, value: number | undefined = undefined): void {
    if (this.tracker != undefined) this.tracker.trackEvent(category, action, name, value);
    else this.events.push([category, action, name, value]);
  }
}

import { getOrElse } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Decoder } from 'io-ts';
import { Lens } from 'monocle-ts';

export const over =
  <A, B>(lens: Lens<A, B>, f: (inp1: B) => B) =>
  (inp: A): A =>
    lens.set(f(lens.get(inp)))(inp);

export const overDecode =
  <A, B>(lens: Lens<A, B>, decoder: Decoder<unknown, B>, f: (inp1: B) => unknown) =>
  (inp: A): A =>
    pipe(
      lens.get(inp),
      f,
      decoder.decode,
      getOrElse(() => lens.get(inp)),
      lens.set,
    )(inp);

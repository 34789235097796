import { iLiteral } from '@dmb/core';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { fromEither } from 'fp-ts/Option';
import * as t from 'io-ts';

// export enum ProductType {
//   Album = 'album',
//   EBook = 'ebook',
//   Movie = 'movie',
//   Series = 'series',
//   Season = 'season',
//   Track = 'track',
//   Episode = 'episode',
//   Physical = 'physical',
// }

const preDecoder = (input: unknown) => {
  if (input === 'serie') return 'series';
  if (input === 'phy-product') return 'physical';
  return input;
};

export const ProductTypeC = iLiteral(
  'Album',
  'EBook',
  'Movie',
  'Series',
  'Season',
  'Track',
  'Episode',
  'Physical',
).addPreprocessor(preDecoder);
export type ProductType = t.TypeOf<typeof ProductTypeC>;
export const ProductTypeLowerC = iLiteral(
  'album',
  'ebook',
  'movie',
  'series',
  'season',
  'track',
  'episode',
  'physical',
).addPreprocessor(preDecoder);
export type ProductTypeLower = t.TypeOf<typeof ProductTypeLowerC>;

export const typeToApiEndpoint = (i: unknown) =>
  pipe(
    i,
    ProductTypeLowerC.decode,
    E.map((pt) => {
      if (pt === 'series') return 'serie';
      if (pt === 'ebook') return 'book';
      if (pt === 'physical') return 'phy-product';
      return pt.toLowerCase();
    }),
    fromEither,
  );

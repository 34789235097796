import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';
import { StompConfig } from './dmb-rx-stomp.config';

@Injectable({ providedIn: 'root' })
export class RxStompService extends RxStomp {
  constructor(private config: StompConfig) {
    super();
    this.configure(config);
    this.activate();
  }
}

import { DmbDateString, partialRecord } from '@dmb/core';
import * as Highcharts from 'highcharts';
import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';
import { DmbLanguage } from '../../model';
import { DashboardWidgetName } from './dashboard-widget-name';
import { ProductTypeLower, ProductTypeLowerC } from './product-type';

export const DataKeyC = t.union([t.literal('streams'), t.literal('downloads')]);
export type DataKey = t.TypeOf<typeof DataKeyC>;
type ThemeColors = {
  downloads: string;
  streaming: string;
  background: string;
  legendHover: string;
  itemHidden: string;
  generalLabel: string;
  xAxisLine: string;
};

const highchartsSeriesCodec = (key: string) =>
  t.type({
    dates: t.array(DmbDateString),
    data: partialRecord(ProductTypeLowerC, t.union([t.type({ [key]: t.array(t.number) }), t.null])),
    total: t.type({ [key]: t.array(t.number) }),
  });

const HighchartsConfigC = (key: string) =>
  t.type({
    metrics: t.array(t.literal(key)),
    clientId: fromNullable(t.number, 0),
    withSubClients: fromNullable(t.boolean, true),
    startDate: fromNullable(DmbDateString, new Date()),
    endDate: fromNullable(DmbDateString, new Date()),
    series: highchartsSeriesCodec(key),
  });
export const StreamsC = HighchartsConfigC('streams');
export const TrendReportsDownloadsC = HighchartsConfigC('downloads');
export type TrendReportsStreams = t.TypeOf<typeof StreamsC>;
export type TrendReportsDownloads = t.TypeOf<typeof TrendReportsDownloadsC>;

const getEmpty = (total: 'streams' | 'downloads') => ({
  metrics: [total],
  clientId: 0,
  withSubClients: false,
  startDate: new Date(),
  endDate: new Date(),
  series: {
    dates: [],
    data: {},
    total: { [total]: [] },
  },
});
export const emptyTrendReportsStreams: TrendReportsStreams = getEmpty('streams');
export const emptyTrendReportsDownloads: TrendReportsDownloads = getEmpty('downloads');

// Trend reports specific
export const LOCALES: Record<DmbLanguage, string> = {
  de: 'de-DE',
  fr: 'fr-FR',
  en: 'en-EN',
};

export const METRICS: Record<PropertyKey, DataKey> = {
  [DashboardWidgetName.TrendReportStreams]: 'streams',
  [DashboardWidgetName.TrendReportDownloads]: 'downloads',
};

const trendReportDefaultLightColors: Record<ProductTypeLower, string> = {
  album: '#427586',
  ebook: '#5b93a6',
  episode: '#73a3b3',
  movie: '#8bb4c1',
  season: '#a3c4ce',
  series: '#bbd4dc',
  track: '#c8dfe6',
  physical: 'red',
};

const trendReportDefaultDarkColors: Record<ProductTypeLower, string> = {
  album: '#427586',
  ebook: '#5b93a6',
  episode: '#73a3b3',
  movie: '#8bb4c1',
  season: '#a3c4ce',
  series: '#bbd4dc',
  track: '#c8dfe6',
  physical: 'red',
};
const lightThemeColors: ThemeColors = {
  downloads: '#427586',
  streaming: '#5b93a6',
  background: '#ffffff',
  legendHover: '#699DAF',
  itemHidden: '#000',
  generalLabel: '#000',
  xAxisLine: '#e6e6e6',
};
const darkThemeColors: ThemeColors = {
  downloads: '#427586',
  streaming: '#5b93a6',
  background: '#383c40',
  legendHover: '#699DAF',
  itemHidden: '#fff',
  generalLabel: '#fff',
  xAxisLine: '#e6e6e6',
};
const legend = (themeColors: ThemeColors): Highcharts.LegendOptions => {
  return {
    floating: false,
    enabled: true,
    itemHoverStyle: {
      color: themeColors.legendHover,
    },
    itemHiddenStyle: {
      color: themeColors.itemHidden,
    },

    backgroundColor: 'transparent',
    borderWidth: 0,
    symbolHeight: 25, // this is some hackery because the symbols are rendering with a blob shape above them.
    symbolWidth: 15,
    layout: 'horizontal',
    verticalAlign: 'bottom',
    align: 'center' as Highcharts.AlignValue,
    itemStyle: {
      color: themeColors.generalLabel,
      fontSize: '1rem',
      fontWeight: 'normal',
      textOverflow: undefined,
    },
  };
};

function xAxis(themeColors: ThemeColors) {
  return {
    title: {
      text: '',
    },
    allowDecimals: false,
    lineColor: themeColors.generalLabel,
    tickColor: themeColors.generalLabel,
    labels: {
      style: {
        color: themeColors.generalLabel,
      },
    },
  };
}

function yAxis(themeColors: ThemeColors) {
  return {
    title: {
      text: '',
    },
    allowDecimals: false,
    labels: {
      useHtml: true,
      style: {
        color: themeColors.generalLabel,
      },
    },
  };
}

const plotOptions = (): Highcharts.PlotOptions => {
  return {
    line: {
      shadow: false,
      marker: {
        enabled: false,
      },
      lineWidth: 0.8,
    },
    series: {
      stacking: 'normal',
    },
  };
};

export function getTrendReportsDefaultColors(theme: string): Record<ProductTypeLower, string> {
  return theme === 'trendReportLightColors' ? trendReportDefaultLightColors : trendReportDefaultDarkColors;
}

export function getTrendReportsConfig(theme: string): Highcharts.Options {
  const themeColors = theme === 'trendReportLightColors' ? lightThemeColors : darkThemeColors;

  return {
    credits: {
      enabled: false,
    },
    title: {
      text: 'Streams (ausgewählter Zeitraum): 7.464',
      style: {
        color: themeColors.generalLabel,
      },
    },
    chart: {
      renderTo: 'id38788jh',
      spacingTop: 5,
      spacingLeft: 5,
      spacingBottom: 5,
      spacingRight: 5,
      backgroundColor: themeColors.background,
    },
    tooltip: {
      formatter(withHeader: Highcharts.Tooltip) {
        const tmpTip = Highcharts.Tooltip.prototype.defaultFormatter.call(this, withHeader);
        const tip = Array.isArray(tmpTip) ? tmpTip : [tmpTip];
        const sum = 0;
        const prevSum = 0;

        const cr = prevSum > 0 ? Math.round((sum / prevSum - 1) * 1000) / 10 : 0;
        let percentage;
        if (cr > 1000) percentage = '> +1000';
        if (cr > 0) percentage = '+' + Highcharts.numberFormat(cr, 1);
        else percentage = Highcharts.numberFormat(cr, 1);
        percentage += '%';
        percentage = percentage === '0%' ? '' : ' (' + percentage + ')';
        tip.push('<b>Total: ' + Highcharts.numberFormat(sum, 0) + '</b>' + percentage);
        return tip;
      },
      shared: true,
    },
    xAxis: {
      ...xAxis(themeColors),
      labels: {
        style: {
          color: themeColors.generalLabel,
        },
      },
    },
    yAxis: yAxis(themeColors),
    plotOptions: plotOptions(),
    legend: legend(themeColors),
  };
}

// Royalties specific
export type RoyaltiesConfig = {
  royaltiesSeriesConfig: Record<string, unknown>;
  royaltiesChartConfig: Record<string, unknown>;
};

export function getRoyaltiesConfig(theme: string) {
  const themeColors = theme === 'lightThemeColors' ? lightThemeColors : darkThemeColors;

  return {
    royaltiesSeriesConfig: {
      downloads: {
        name: 'Download',
        type: 'areaspline',
        color: themeColors.downloads,
        fillColor: themeColors.downloads,
        marker: {
          enabled: false,
        },
        stacking: 'normal',
      },
      streaming: {
        name: 'Streaming',
        type: 'areaspline',
        color: themeColors.streaming,
        fillColor: themeColors.streaming,
        marker: {
          enabled: false,
        },
        stacking: 'normal',
      },
    },
    royaltiesChartConfig: {
      credits: {
        enabled: false,
      },
      title: 'A Title',
      chart: {
        renderTo: 'id33o2l1a',
        spacingTop: 5,
        spacingLeft: 0,
        spacingBottom: 0,
        spacingRight: 0,
        backgroundColor: themeColors.background,
      },
      tooltip: {
        pointFormat: '{point.series.name}:<b>{point.y:,.2f}</b><br/>',
        shared: true,
      },
      subtitle: null,
      plotOptions: plotOptions(),
      legend: legend(themeColors),
      xAxis: xAxis(themeColors),
      yAxis: yAxis(themeColors),
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 800,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
              },
            },
          },
        ],
      },
    },
  };
}

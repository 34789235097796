import { animate, animation, state, style, transition, trigger } from '@angular/animations';

export const slideUpTrigger = trigger('slideUp', [
  state('inView', style({ transform: 'translateY(0)' })),
  state('outView', style({ transform: `translateY({{range}})` }), { params: { range: '100%' } }),
  transition('* => inView', animate('{{duration}}ms {{delay}}ms'), {
    params: {
      duration: 800,
      delay: 0,
      range: '50px',
    },
  }),
]);

export const slideUpAnimation = animation([
  style({ transform: 'translate({{range}})' }),
  animate('{{duration}}ms {{delay}}ms'),
]);

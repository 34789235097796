import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'dmb-login-errors',
  templateUrl: './login-errors.component.html',
  styleUrls: ['./login-errors.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class LoginErrorsComponent {
  @Input() status = 0;
  @Input() message = '';
}

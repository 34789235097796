import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../../services';

@Component({
  selector: 'dmb-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf],
})
export class SpinnerComponent {
  protected readonly Array = Array;
  @Input() loader: LoadingService = this._loader;

  constructor(private _loader: LoadingService) {}
}

import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, OnDestroy } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoBrandingService } from '../../../../services';
import { FocusService } from '../../../../services/focus.service';
import { SwitchClientComponent } from '../../../modal/switch-client/switch-client.component';
import { SearchComponent } from '../../../search/search.component';
import { MenuService } from '../menu.service';
import { SvgIconComponent } from '@dmb/core';

@Component({
  selector: 'dmb-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
  standalone: true,
  imports: [MatIcon, AsyncPipe, NgClass, SearchComponent, SwitchClientComponent, SvgIconComponent],
})
export class NavHeaderComponent implements OnDestroy {
  @HostBinding('class.searchActive') searchActive = false;
  logo = this.coBrandingService.logo;
  coBrand = this.coBrandingService.coBrand;
  sub = this.menuService.active.pipe(map((a) => a === 'searchBar')).subscribe((v) => (this.searchActive = v));
  toggleIcon = this.menuService.mainMenuCollapsed.pipe(map((v) => (v ? 'add' : 'remove')));

  constructor(
    private menuService: MenuService,
    private focusService: FocusService,
    private coBrandingService: CoBrandingService,
  ) {}

  async toggleMainMenu() {
    return this.menuService.toggleMenu();
  }

  async expandSearch($event: MouseEvent) {
    $event.stopPropagation();

    const collapsed = await firstValueFrom(this.menuService.mainMenuCollapsed);
    if (collapsed) await this.toggleMainMenu();

    // 500ms delay because setting focus before the css transition completes
    // causes the whole menu to shift.
    setTimeout(() => this.focusService.setFocus('search'), 500);

    // this.menuService.setOrToggleActive('searchBar');
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleSearch(isSearching: boolean) {
    if (isSearching) this.menuService.setActive(null);
  }
}

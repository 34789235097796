import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';

// Helper type to extract enum values
//
// export function fromEnum<EnumType extends string>(
//   enumName: string,
//   theEnum: Record<string, EnumType>,
// ): t.Type<EnumType, EnumType, unknown> {
//   const isEnumValue = (input: unknown): input is EnumType => Object.values<unknown>(theEnum).includes(input);
//
//   return new t.Type<EnumType>(
//     enumName,
//     isEnumValue,
//     (input, context) => (isEnumValue(input) ? t.success(input) : t.failure(input, context)),
//     t.identity,
//   );
// }

export class EnumType<A> extends t.Type<A> {
  public readonly _tag = 'EnumType' as const;
  public enumObject!: object;

  public constructor(e: object, name?: string, predecoder: (s: unknown) => unknown = t.identity) {
    const vals = Object.values(e);
    super(
      name || 'enum',
      (u): u is A => {
        if (!vals.includes(u as A)) return false;
        // enum reverse mapping check
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return typeof e[u as string] !== 'number';

        // return typeof (this.enumObject as any)[u as string] !== 'number';
      },
      (u, c) => pipe(predecoder(u), (v) => (this.is(v) ? t.success(v) : t.failure(u, c))),
      t.identity,
    );
    this.enumObject = e;
  }
}

// simple helper function
export const createEnumCodec = <T>(e: object, name?: string, preDecoder: (s: unknown) => unknown = (s) => s) =>
  new EnumType<T>(e, name, preDecoder);

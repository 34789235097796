import { Pipe, PipeTransform } from '@angular/core';
import { decapitalize } from '../decapitalize';

@Pipe({
  name: 'decapitalize',
  standalone: true,
})
export class DecapitalizePipe implements PipeTransform {
  transform(value: string | null): string {
    return value != null ? decapitalize(value) : '';
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FocusService {
  private focusTrigger = new Subject<string>();
  currentFocus: Observable<string> = this.focusTrigger;

  setFocus(key: string) {
    this.focusTrigger.next(key);
  }
}

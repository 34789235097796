<ng-container *ngIf="showLogin | async">
  <div class="dmb-page-wrapper no-menu">
    <dmb-login *ngIf="showLogin | async"></dmb-login>
  </div>
</ng-container>
<ng-container *ngIf="(showLogin | async) === false">
  <div class="dmb-page-wrapper" id="main-scroller">
    <dmb-combined-menu></dmb-combined-menu>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<dmb-spinner></dmb-spinner>

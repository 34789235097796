import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filterMap } from 'fp-ts-rxjs/Observable';
import { pipe } from 'fp-ts/function';
import { fromEither } from 'fp-ts/Option';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { APIV4 } from '../../components/modal';
import { DmbClient, DmbLabel, SessionLabelOption, UserSession, UserSessionC } from '../../model';

import { UserService } from '../../user';
import { ClientService } from '../client/client.service';
import { CommsService } from '../comms';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private reloader = new BehaviorSubject(true);
  session = this.reloader.pipe(
    mergeMap(() => this.comms.onMessage<Partial<UserSession>>('/user/session')),
    map(UserSessionC.decode),
    filterMap(fromEither),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  selectedClientId = pipe(
    this.session,
    map((s) => s.selectedClientId),
    distinctUntilChanged(),
  );

  isImpersonated = this.session.pipe(
    map((s) => s.isImpersonated),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  unimpersonate = this.http.get<{ userId: number }>(`${APIV4}users/revoke_impersonation`).pipe(
    tap(() => this.userService.reload()),
    tap((res) => this.router.navigate([`/page/USERS.edit/${res.userId}`])),
  );

  impersonate(userId: number) {
    return this.http.post(`${APIV4}users/impersonate`, { userId }).pipe(
      tap(() => this.userService.reload()),
      catchError(() => {
        console.error(`something went wrong trying to impersonate user: ${userId}`);
        return [];
      }),
    );
  }

  constructor(
    private comms: CommsService,
    private userService: UserService,
    private clientService: ClientService,
    private http: HttpClient,
    private router: Router,
  ) {}

  client = combineLatest([this.session, this.userService.authenticatedUser]).pipe(
    mergeMap(([session, user]) =>
      this.clientService.clients.pipe(map((clients) => clients[session.selectedClientId || user.clientId])),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  labels = this.clientService.labels;

  labelOption = combineLatest([this.session, this.labels]).pipe(
    map(([session, lbls]): SessionLabelOption => {
      const lbl = session.selectedLabelId ? lbls.find((l) => l.id === session.selectedLabelId) : undefined;
      return {
        selectable: lbls.length > 1,
        name: lbl ? lbl.name : lbls.length > 1 ? $localize`:@@Common.label.all-labels:All Labels` : '',
        label: lbl || null,
      };
    }),
  );

  async unsetClient() {
    this.switchClient(await firstValueFrom(this.clientService.client));
  }

  switchClient(client: DmbClient) {
    this.comms.send('/session/update', { selectedClient: client.id });
  }

  switchLabel(newLabel: DmbLabel) {
    this.comms.send('/session/update', { selectedLabel: newLabel.id });
  }

  unsetLabel() {
    this.comms.send('/session/update', { unsetLabel: true });
  }

  async logout() {
    return firstValueFrom(
      this.isImpersonated.pipe(switchMap((impersonated) => (impersonated ? this.unimpersonate : this.doLogout()))),
    );
  }

  private async doLogout() {
    this.userService.logout();
    return EMPTY;
  }
}

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterOutlet } from '@angular/router';
import { DmbUiModule, environment } from '@dmb/dmb-ui';

import * as Sentry from '@sentry/angular';
import { AppComponent } from './app.component';

const provideSentry: () => Provider[] = () => {
  if (environment.sentryDsn) {
    return [
      { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
      { provide: Sentry.TraceService, deps: [Router] },
      // eslint-disable-next-line
      { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    ];
  }
  return [];
};

@NgModule({
  imports: [BrowserModule, DmbUiModule, RouterOutlet, AppComponent],
  providers: [provideAnimations(), provideHttpClient(withInterceptorsFromDi()), provideSentry()],
})
export class AppModule {}

<mat-card class="switch-label-card">
  <form id="selectLabel">
    <dmb-card-header (closeModal)="close()">
      <span i18n="@@switch.label.change">Current: </span>
      <span *ngIf="current | async; let lbl"> {{ lbl.name }}</span>
    </dmb-card-header>

    <mat-card-content class="switch-label-card-content" *ngIf="labels">
      <dmb-filter
        [listItems]="labels"
        filterField="name"
        (dataChange)="select($event)"
        [selected]="selected"
        [allLabels]="showAll"
      ></dmb-filter>
    </mat-card-content>
  </form>
</mat-card>

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'dmb-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  standalone: true,
})
export class QrCodeComponent implements OnInit {
  @Input() qrCode: SafeUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.qrCode = this.sanitizer.bypassSecurityTrustUrl(`data:image/PNG;base64,${this.qrCode}`);
  }
}

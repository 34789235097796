import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { NavigationSkipped, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filterMap } from 'fp-ts-rxjs/Observable';
import { pipe } from 'fp-ts/function';
import { none, some } from 'fp-ts/Option';
import { withLatestFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FrameService } from '../../../../iframe/frame.service';
import { LayoutTranslation } from '../layout-translation';
import { MenuService } from '../menu.service';

@Component({
  selector: 'dmb-submenu-item',
  templateUrl: './submenu-item.component.html',
  styleUrls: ['./submenu-item.component.scss'],
  standalone: true,
  imports: [AsyncPipe, RouterLink, NgIf, RouterLinkActive],
})
export class SubmenuItemComponent implements OnDestroy {
  @Input() path = '';
  @Input() alwaysEnabled = false;
  @Input() key?: keyof LayoutTranslation;
  @Input() queryParams: { [key: string]: string | number } = {};
  @Output() activeChange = new EventEmitter();
  @Input() watchClick = false;

  @HostBinding('class.active') active = false;
  @HostBinding('class.disabled') disabled = false;

  enabled = pipe(
    this.menuService.menu,
    map((m) => this.alwaysEnabled || (this.key && m.includes(this.key))),
    tap((v) => (this.disabled = !v)),
  );
  tr = LayoutTranslation.getTranslations();
  sub = pipe(
    this.router.events,
    filterMap((e) =>
      this.watchClick && this.active && e instanceof NavigationSkipped ? some(this.queryParams) : none,
    ),
    withLatestFrom(this.v3.frameAvailable),
    filterMap(([q, active]) => (active ? some(q) : none)),
  ).subscribe((payload) => this.v3.postMessage('overlay-request', payload));

  constructor(
    private menuService: MenuService,
    private el: ElementRef,
    private router: Router,
    private v3: FrameService,
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  deactivate() {
    this.menuService.setActive(null);
  }

  label(key?: keyof LayoutTranslation) {
    if (!key) return this.path;
    return this.tr[key] ?? this.path;
  }
}

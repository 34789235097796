import { isLeft } from 'fp-ts/Either';
import { flow } from 'fp-ts/function';
import { fromEither } from 'fp-ts/Option';
import { Decoder, Validation } from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';

export const filterDecodeWithLog = <T>(decoder: Decoder<unknown, T>) =>
  flow(
    decoder.decode,
    (q): Validation<T> => {
      if (isLeft(q)) console.log('there were errors decoding', PathReporter.report(q));
      return q;
    },
    fromEither,
  );

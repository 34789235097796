<ng-container *ngIf="enabled | async">
  <a
    [routerLink]="path"
    (click)="deactivate()"
    routerLinkActive="active"
    (isActiveChange)="active = $event"
    [queryParams]="queryParams"
    >{{ label(key) }}</a
  >
</ng-container>

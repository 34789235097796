import { Change, diffWords } from 'diff';

export const showDiffForHtmlDisplay = (orig: string) => (newValue: string) => {
  return diffWords(orig, newValue)
    .map((d: Change) => {
      if (d.removed) return `<span class="removed">${d.value}</span>`;
      if (d.added) return `<span class="added">${d.value}</span>`;
      return d.value;
    })
    .join('');
};

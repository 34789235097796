import { HttpEvent, HttpEventType, HttpResponse, HttpUploadProgressEvent } from '@angular/common/http';

export function isNonAngularUrl(redirectTo: string) {
  if (!redirectTo) return false;
  if (redirectTo === '/') return false;
  if (redirectTo.startsWith('v4/')) return false;
  return !redirectTo.startsWith('/v4');
}

export function buildV3Url(redirectTo: string) {
  return `/_/${redirectTo}`;
}

export const isProgressEvent = (e: HttpEvent<unknown>): e is HttpUploadProgressEvent =>
  e.type === HttpEventType.UploadProgress;

export const isResponse = (e: HttpEvent<unknown>): e is HttpResponse<unknown> => e.type === HttpEventType.Response;

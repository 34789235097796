import { Injectable } from '@angular/core';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  public async getFingerprint(): Promise<string | null> {
    const fp = await FingerprintJS.load();
    return (await fp.get()).visitorId;
  }
}

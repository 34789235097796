import { hasProp } from './typing';

export const traverse = <T extends object, K extends keyof T>(path: string, obj?: T): T[K] | null => {
  if (!obj) return null;
  const parts = path.split('.');
  const key = parts.shift();
  if (!key) return null;
  if (!hasProp(obj, key)) return null;
  const arg = obj[key];
  if (parts.length > 0 && typeof arg === 'object' && arg != null) return traverse(parts.join('.'), arg);
  return obj[key as K];
};

export const extract = <T extends object>(paths: [string, string][], obj?: T): object | null => {
  return paths.reduce((r, [k, p]) => ({ ...r, [k]: traverse(p, obj) }), {});
};

import { codecFromArray } from '@dmb/core';
import * as t from 'io-ts';

export const [CommsTypeC, commsTypes] = codecFromArray([
  'email',
  'phone',
  'mobile',
  'facebook',
  'fax',
  'skype',
  'x',
  'instagram',
  'tiktok',
  'twitch',
  'twitter',
]);

export type CommsType = t.TypeOf<typeof CommsTypeC>;
export const CommsObjectC = t.type({
  type: t.union([CommsTypeC, t.literal('email'), t.literal('twitter'), t.literal('fax')]),
  value: t.string,
});
export type CommsTypeObject = t.TypeOf<typeof CommsObjectC>;
const twitter = $localize`:@@user-settings.accounts.comms.twitter:X (Twitter)`;
export const ChannelTypeI18n: Record<CommsType, string> = {
  email: $localize`:@@user-settings.accounts.comms.email:Email`,
  mobile: $localize`:@@user-settings.accounts.comms.mobile:Mobile`,
  phone: $localize`:@@user-settings.accounts.comms.phone:Phone`,
  skype: $localize`:@@user-settings.accounts.comms.skype:Skype`,
  x: $localize`:@@user-settings.accounts.comms.twitter:X (Twitter)`,
  fax: $localize`:@@user-settings.accounts.comms.fax:Fax`,
  twitter,
  facebook: $localize`:@@user-settings.accounts.comms.facebook:Facebook`,
  instagram: $localize`:@@user-settings.accounts.comms.instagram:Instagram`,
  tiktok: $localize`:@@user-settings.accounts.comms.tiktok:TikTok`,
  twitch: $localize`:@@user-settings.accounts.comms.twitch:Twitch`,
};
export const ChannelTypes: CommsTypeObject[] = (Object.entries(ChannelTypeI18n) as [CommsType, string][]).map(
  ([type, value]) => ({
    type,
    value,
  }),
);

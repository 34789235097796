import { forwardRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => DefaultLoadingService),
})
export abstract class LoadingService {
  abstract isLoading: Observable<boolean>;
  abstract mode: string;

  abstract setLoading(loading: boolean): void;
}

@Injectable({ providedIn: 'root' })
export class DefaultLoadingService implements LoadingService {
  private loading = new BehaviorSubject(false);
  isLoading: Observable<boolean> = this.loading.pipe(distinctUntilChanged());
  mode = 'spinner';

  setLoading(loading: boolean): void {
    this.loading.next(loading);
  }
}

export const loadingServiceFromObservable: (obs: Observable<boolean>, mode?: string) => LoadingService = (
  o,
  mode = 'spinner',
) => ({
  isLoading: o,
  mode,
  setLoading: () => {
    // do nothing
  },
});

<nav [ngClass]="{ transitioned: transitioned | async }">
  <div class="container">
    <dmb-nav-header></dmb-nav-header>
    <div class="main" scrollableTrigger>
      <dmb-top-level key="dashboard" path="dashboard" icon="home" [noText]="true" [alwaysEnabled]="true">
        <div i18n="@@menu.dashboard">Dashboard</div>
      </dmb-top-level>

      <dmb-top-level key="music" path="page/albums" icon="music_video">
        <dmb-submenu-item key="music.album.search" path="page/albums" />
        <dmb-submenu-item
          key="music.album.create"
          path="page/albums"
          [queryParams]="{ dmbOpenLayerURL: 'page/album.create' }"
          [watchClick]="true"
        />
        <dmb-submenu-item key="music.track.search" path="page/tracks" />
        <dmb-submenu-item
          key="music.track.create"
          path="page/tracks"
          [queryParams]="{ dmbOpenLayerURL: 'page/track-create' }"
          [watchClick]="true"
        />
        <dmb-submenu-item key="music.deliveries" path="page/deliveries" />
        <dmb-submenu-item key="music.bulkDeliveries" path="page/mass-delivery-search" />
        <dmb-submenu-item key="music.videoMaker" path="page/track-video-maker" />
        <dmb-submenu-item key="music.track.dmSearch" path="page/track-dm-search" />
      </dmb-top-level>
      <dmb-top-level key="physical" path="page/product-project-search" icon="physical">
        <dmb-submenu-item key="physical.project.search" path="page/product-project-search" />
        <dmb-submenu-item
          key="physical.project.add"
          path="page/product-project-search"
          [queryParams]="{ dmbOpenLayerURL: 'page/product-project' }"
          [watchClick]="true"
        />
        <dmb-submenu-item key="physical.product.search" path="page/phy-product.search" />
        <dmb-submenu-item key="physical.product.add" path="page/phy-product" />
        <dmb-submenu-item key="physical.track.search" path="page/phy-product.track-search" />
        <dmb-submenu-item
          key="physical.track.add"
          path="page/phy-product.track-search"
          [queryParams]="{ dmbOpenLayerURL: 'page/phy-product.track' }"
          [watchClick]="true"
        />
        <dmb-submenu-item key="physical.sales-document.search" path="page/phy-product.sales-document-search" />
      </dmb-top-level>
      <dmb-top-level key="pitch" path="page/product-pitch-search" icon="product_pitching">
        <dmb-submenu-item key="pitch.search" path="page/product-pitch-search" />
        <dmb-submenu-item key="pitch.releaseSchedule" path="page/product-release-schedule-search" />
      </dmb-top-level>
      <dmb-top-level key="movies" path="page/movies" icon="movie">
        <dmb-submenu-item key="movies.movie.search" path="page/movies" />
        <dmb-submenu-item key="movies.movie.create" path="page/movie" />
        <dmb-submenu-item
          key="movies.movie.create.wizard"
          path="page/movies"
          [queryParams]="{ dmbOpenLayerURL: 'page/movie.create' }"
          [watchClick]="true"
        />
        <dmb-submenu-item key="movies.deliveries" path="page/movie.deliveries" />
        <dmb-submenu-item key="movies.bulkDeliveries" path="page/movie-bulk-deliveries" />
      </dmb-top-level>
      <dmb-top-level key="tv" path="page/series" icon="series">
        <dmb-submenu-item key="tv.series.search" path="page/series" />
        <dmb-submenu-item key="tv.series.create" path="page/serie" />
        <dmb-submenu-item key="tv.seasons.search" path="page/seasons" />
        <dmb-submenu-item key="tv.episodes.search" path="page/episodes" />
        <dmb-submenu-item key="tv.deliveries" path="page/episode-deliveries" />
        <dmb-submenu-item key="tv.bulkDeliveries" path="page/episode-bulk-deliveries" />
      </dmb-top-level>
      <dmb-top-level key="books" path="page/book.search" icon="ebook">
        <dmb-submenu-item key="books.book.search" path="page/book.search" />
        <dmb-submenu-item key="books.book.create" path="page/book" />
        <dmb-submenu-item key="books.deliveries" path="page/book-deliveries" />
        <dmb-submenu-item key="books.bulkDeliveries" path="page/book-bulk-deliveries" />
      </dmb-top-level>
      <dmb-top-level key="youtube" path="youtube/video-manager" icon="youtube">
        <dmb-submenu-item key="youtube.videoManager" path="youtube/video-manager" />
        <dmb-submenu-item key="youtube.management" path="page/youtube" />
        <dmb-submenu-item key="youtube.managementLegacy" path="page/yt-old-assets" />
      </dmb-top-level>
      <dmb-top-level key="trends" path="page/trendreport" icon="trend_up">
        <dmb-submenu-item key="trends.view" path="page/trendreport" />
        <dmb-submenu-item key="trends.report.own" path="page/gfk-own-repertoire" />
        <dmb-submenu-item key="trends.report.global" path="page/gfk-global-report" />
        <dmb-submenu-item key="trends.awards" path="page/trends.awards" />
        <dmb-submenu-item key="trends.unmatchedLines" path="page/sales.unmatched.lines" />
      </dmb-top-level>
      <dmb-top-level key="royalties" path="page/royalties" icon="royalities" />
      <dmb-top-level key="contributors" path="page/persons" icon="persons" />
      <dmb-top-level key="ilas" path="page/ilas.dashboard" icon="sap" />
      <dmb-top-level key="main.productBags" path="/page/product-bags" icon="shopping_card" />
      <dmb-top-level key="main.news" path="page/news" icon="news" />
      <dmb-top-level key="main.labelManager" path="page/my-operator" icon="voice" />
      <dmb-top-level key="main.dmbForArtists" path="page/shared-product-users" icon="person" />

      <dmb-top-level key="customers" icon="client">
        <dmb-submenu-item key="customers.clients" path="page/clients" />
        <dmb-submenu-item key="customers.licensors" path="page/licensors" />
        <dmb-submenu-item key="customers.contracts" path="page/contracts" />
        <dmb-submenu-item key="customers.users" path="page/USERS.search" />
        <dmb-submenu-item key="customers.userGroups" path="page/USERS.groups" />
        <dmb-submenu-item key="common.countries" path="page/countries" />
      </dmb-top-level>
      <dmb-top-level key="statistics" icon="charts">
        <dmb-submenu-item key="statistics.productTickets" path="page/product-msg-search" />
        <dmb-submenu-item key="statistics.qualityCheck" path="page/qc-products" />
        <dmb-submenu-item key="statistics.qualityCheck.report" path="page/Client.QC-Report" />
        <dmb-submenu-item key="statistics.turnaround" path="page/Client.Turnaround-Report" />
        <dmb-submenu-item key="statistics.products" path="page/client-stats" />
        <dmb-submenu-item key="statistics.accounting" path="page/billing-stats" />
        <dmb-submenu-item key="statistics.delivery" path="page/Delivery-Statistics" />
        <dmb-submenu-item key="statistics.encoding" path="page/encoder-stats" />
        <dmb-submenu-item key="statistics.availableRetailers" path="page/retailers" />
        <dmb-submenu-item key="statistics.availableGenres" path="page/genres" />
        <dmb-submenu-item key="statistics.distributionNetwork" path="distribution-network" />
      </dmb-top-level>
      <dmb-top-level key="system" icon="settings">
        <dmb-submenu-item key="system.manageRetailers" path="page/search-retailers" />
        <dmb-submenu-item key="system.manageStores" path="page/stores" />
        <dmb-submenu-item key="system.manageTrendReports" path="page/sales.search" />
        <dmb-submenu-item key="system.manageExternalHDs" path="page/hdds" />
        <dmb-submenu-item key="system.systemStatus" path="page/sys-monitor" />
        <dmb-submenu-item key="system.systemDv" path="page/sys-dv" />
        <dmb-submenu-item key="system.manualProcesses" path="page/manual-processes" />
        <dmb-submenu-item key="system.appleDigitalMasters" path="page/apple-digital-masters-search" />
        <dmb-submenu-item key="system.artistStoreIssues" path="page/search-person-store-issue" />
      </dmb-top-level>
      <dmb-top-level key="common" icon="settings">
        <dmb-submenu-item key="common.labels" path="page/labels" />
        <dmb-submenu-item key="common.genres" path="page/genre-manage" />
        <dmb-submenu-item key="common.keywords" path="page/clean-keywords-list" />
        <dmb-submenu-item key="common.qcDenials" path="page/qc-messages" />
        <dmb-submenu-item key="common.regions" path="page/regions" />
        <dmb-submenu-item key="common.countries" path="page/countries" />
        <dmb-submenu-item key="common.promoSettings" path="page/promo-settings" />
        <dmb-submenu-item key="common.manageFAQs" path="page/faq-search" />
        <dmb-submenu-item key="common.productCodeSearch" path="page/product-code-search" />
      </dmb-top-level>
    </div>
    <div class="footer">
      <dmb-top-level key="personal" icon="person" [alwaysEnabled]="true">
        <dmb-submenu-item key="personal.account" path="user/settings" [alwaysEnabled]="true" />
        <dmb-submenu-item key="personal.company" path="page/my-company" />
      </dmb-top-level>

      <dmb-top-level key="main.support" [alwaysEnabled]="true" icon="help">
        <dmb-submenu-item key="main.help" path="page/support-documents" />
        <dmb-submenu-item key="main.faqs" path="page/faqs" />
      </dmb-top-level>
      <dmb-top-level
        (activate)="logout()"
        [alwaysEnabled]="true"
        key="logout"
        [icon]="(logoutIcon | async) || 'off'"
        [warn]="true"
        [noText]="true"
      >
        <span i18n="@@menu.logout">Log out</span><span>&nbsp;{{ realName$ | async }}</span>
      </dmb-top-level>
    </div>
  </div>
</nav>

<div
  class="client-label"
  *ngIf="client | async; let cl"
  [ngClass]="{ selectable: clientSelectable | async, 'animated-label': cl.name.length > 18 }"
  (click)="selectClient()"
>
  {{ cl.name }}
</div>
<div class="label" *ngIf="labelOption | async; let lbl" [ngClass]="getClasses(lbl)" (click)="selectLabel(lbl)">
  {{ lbl.name }} <svg-icon icon="icon_arrow_down" *ngIf="lbl.selectable"></svg-icon>
</div>

import { createEnumCodec } from '@dmb/core';

export enum DashboardWidgetName {
  News = 'News',
  UpcomingReleases = 'UpcomingReleases',
  QualityCheck = 'QualityCheck',
  ProductIncomplete = 'ProductIncomplete',
  LastSeen = 'LastSeen',
  ChartEntries = 'ChartEntries',
  ImportedRecently = 'ImportedRecently',
  ImportErrors = 'ImportErrors',
  TicketProducts = 'TicketProducts',
  TicketFixed = 'TicketFixed',
  Playlisted = 'Playlisted',
  Unplaylisted = 'Unplaylisted',
  PlaylistClimbers = 'PlaylistClimbers',
  PlaylistFallers = 'PlaylistFallers',
  TrendReportStreams = 'TrendReportStreams',
  TrendReportDownloads = 'TrendReportDownloads',
  Royalties = 'Royalties',
  PhysicalSaleIn = 'PhysicalSaleIn',
  PhysicalInStore = 'PhysicalInStore',
  ProjectsQC = 'ProjectsQC',
}

export type WidgetName = keyof typeof DashboardWidgetName;
export const DashboardWidgetNameC = createEnumCodec<DashboardWidgetName>(DashboardWidgetName, 'DashboardWidgetName');
export const uncapitalizeWN = (name: DashboardWidgetName): Uncapitalize<DashboardWidgetName> => {
  return (name[0].toLocaleLowerCase() + name.slice(1)) as Uncapitalize<DashboardWidgetName>;
};

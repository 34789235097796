import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { v5path } from './v5path';

@Injectable({ providedIn: 'root' })
export class CommsAuthService {
  private refreshTokenTrigger = new BehaviorSubject(true);

  token = this.refreshTokenTrigger.pipe(
    mergeMap(() =>
      this.http.get<string>(`${v5path()}/dmbauth`, { withCredentials: true }).pipe(catchError(() => of(''))),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  authenticated = this.token.pipe(map((v) => !!v));

  public constructor(private http: HttpClient) {}

  public refreshToken() {
    this.refreshTokenTrigger.next(true);
  }
}

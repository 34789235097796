<div class="filter">
  <mat-form-field class="example-form-field" appearance="outline" floatLabel="always">
    <svg-icon matPrefix icon="icon_search"></svg-icon>
    <input matInput type="text" [(ngModel)]="filterVal" (keyup)="filter()" ngDefaultControl />
    <button *ngIf="filterVal" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
      <svg-icon icon="icon_close"></svg-icon>
    </button>
  </mat-form-field>
  <div class="filter-list">
    <div class="filter-item" (click)="clearFilter()" i18n="@@switch.label.select.all" *ngIf="allLabels">
      Alle auswählen
    </div>
    <div
      class="filter-item"
      *ngFor="let item of filteredItems; let i = index"
      (click)="select(item, i)"
      [ngClass]="{
        selected: selectedIndex === i,
        first: $any(item)['level'] === 1,
        second: $any(item)['level'] === 2,
        third: $any(item)['level'] === 3,
      }"
    >
      {{ $any(item)[filterField] }}
    </div>
    <div *ngIf="filteredItems.length <= 0" i18n="@@switch.label.notFound">Keine Ergebnisse gefunden.</div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { shareLast, sortByStringProp } from '@dmb/core';
import { pipe } from 'fp-ts/function';

import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DmbClient, DmbLabel } from '../../model';
import { UserService } from '../../user';

import { CommsService } from '../comms';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  // this handles broadcast events about the client tree
  // when the client hierarchy changes we need to send a ping
  // and the server will push the updates
  private clientEvents = pipe(
    this.comms.onMessage<unknown>('/client/events'),
    tap(() => this.comms.send('/client', 1)),
  ).subscribe();

  clients = pipe(this.comms.onMessage<Record<number, DmbClient>>('/user/client'), shareLast());

  client: Observable<DmbClient> = combineLatest([this.userService.authenticatedUser, this.clients]).pipe(
    map(([u, clients]) => clients[u.clientId]),
    shareLast(),
  );
  labels = this.comms.onMessage<DmbLabel[]>('/user/labels').pipe(
    map((l) => sortByStringProp(l, 'name')),
    shareLast(),
  );

  constructor(
    private comms: CommsService,
    private userService: UserService,
  ) {}
}

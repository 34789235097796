import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { Subscription } from 'rxjs';
import { toggleBackgroundBlur } from '../../../../helper/modal.helper';
import { DmbLabel } from '../../../../model';
import { SessionService } from '../../../../services';
import { CardHeaderComponent } from '../../../card/header/card-header.component';
import { FilterComponent } from '../../../form/filter/filter.component';

@Component({
  selector: 'dmb-select-label',
  templateUrl: './select-label.component.html',
  styleUrls: ['../../modal.scss', './select-label.component.scss'],
  standalone: true,
  imports: [MatCard, CardHeaderComponent, MatCardContent, AsyncPipe, NgIf, FilterComponent],
})
export class SelectLabelComponent implements OnDestroy {
  @Output() closeModal = new EventEmitter<void>();

  labels: DmbLabel[] = [];
  selected: DmbLabel | null = null;
  current = this.sessionService.labelOption;
  showAll = true;
  subscription: Subscription = this.sessionService.labelOption.subscribe((opt) => (this.selected = opt.label));

  constructor(
    private sessionService: SessionService,
    private dialogRef: DialogRef,
  ) {
    this.sessionService.labels.subscribe((s) => (this.labels = s));
  }

  select(label: DmbLabel) {
    if (label) {
      this.sessionService.switchLabel(label);
    } else {
      this.sessionService.unsetLabel();
    }
    this.close();
  }

  reset() {
    this.sessionService.unsetLabel();
    this.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.dialogRef.close();
    this.closeModal.emit();
    toggleBackgroundBlur();
  }
}

import { ElementRef } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';

export class InViewDirective {
  inView = new Observable<IntersectionObserverEntry[]>((obs) => {
    const intObs = new IntersectionObserver((entries) => obs.next(entries), { threshold: this.threshold });
    intObs.observe(this.el.nativeElement);
    return () => intObs.unobserve(this.el.nativeElement);
  }).pipe(
    map((entries) => entries.some((e) => e.isIntersecting)),
    distinctUntilChanged(),
  );

  constructor(
    private el: ElementRef,
    private threshold = 0.4,
  ) {}
}

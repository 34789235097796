import { DmbDateString } from '@dmb/core';
import * as t from 'io-ts';
import { fromNullable } from 'io-ts-types';

export type RoyaltiesSeries = {
  dates: number[];
  downloads: string[];
  streaming: string[];
};
export const RoyaltiesHighchartsDataC = t.type({
  startYear: t.number,
  endYear: t.number,
  currency: fromNullable(t.string, ''),
  series: t.type({
    dates: t.array(DmbDateString),
    downloads: t.array(t.string),
    streaming: t.array(t.string),
  }),
});
export type RoyaltiesHighChartData = t.TypeOf<typeof RoyaltiesHighchartsDataC>;
// export interface RoyaltiesHighChartData {
//   readonly series: {
//     dates: Date[];
//     downloads: string[];
//     streaming: string[];
//   };
//   readonly startYear: number;
//   readonly endYear: number;
//   readonly currency: string;
// }

export const emptyRoyaltiesHighChartData: RoyaltiesHighChartData = {
  series: {
    dates: [],
    downloads: [],
    streaming: [],
  },
  startYear: 0,
  endYear: 0,
  currency: '',
};

import { ProductType } from './product-type';

const typesKeys = (): Record<ProductType, string> => ({
  Album: $localize`:@@product-type.album:Album`,
  EBook: $localize`:@@product-type.ebook:E-Book`,
  Movie: $localize`:@@product-type.movie:Movie`,
  Series: $localize`:@@product-type.series:Series`,
  Season: $localize`:@@product-type.season:Season`,
  Track: $localize`:@@product-type.track:Track`,
  Episode: $localize`:@@product-type.episode:Episode`,
  Physical: $localize`:@@product-type.physical:Physical`,
});
export const productTypeI18n = (type: ProductType) => typesKeys()[type];

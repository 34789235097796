import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isMenuCollapsed } from '../../helper/menu.helper';
import { FocusService } from '../../services/focus.service';
import { InputComponent } from '../form';

@Component({
  selector: 'dmb-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [InputComponent],
})
export class SearchComponent implements OnDestroy {
  @Output() searching = new EventEmitter();

  public searchString = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]);

  @ViewChild(InputComponent) theInput: InputComponent | null = null;
  subscription = this.searchString.valueChanges.subscribe(() => this.untouchedWhenEmpty());

  constructor(
    private _router: Router,
    private focusService: FocusService,
  ) {
    this.subscription.add(
      this.focusService.currentFocus.pipe(filter((v) => v === 'search')).subscribe(() => {
        this.focus();
      }),
    );
  }

  untouchedWhenEmpty() {
    if (!this.searchString.getRawValue()) {
      this.searchString.markAsUntouched();
      if (isMenuCollapsed()) {
        this.searching.emit(false);
      }
    }
  }

  public submitSearch(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.searchString.valid) {
      console.log(this.searchString.errors);
      return;
    }

    const urlTree: UrlTree = this._router.createUrlTree(['/page/products'], {
      queryParams: {
        phrase: this.searchString.getRawValue(),
        doSearch: 1,
        action: 'search',
        page: 'products', // this.searchType.type,
        'dmb-v3': 1,
      },
    });

    this.searchString.reset();
    this.searching.emit(true);
    return this._router.navigateByUrl(urlTree);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  focus() {
    this.theInput?.focus();
  }
}
